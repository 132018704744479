import React, { useEffect } from 'react';
import '../App.css';

function Mars() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="mars">
        <h1>Mars Sign in Vedic Astrology</h1>
        
        <p>Mars is a fiery, energetic planet that signifies action, passion, and drive. It is often associated with aggression, war, and conflict, but it can also represent courage, strength, and determination. In Vedic astrology, Mars is known as Mangala, and it is considered a malefic planet. However, like all planets, Mars can also have positive effects depending on its placement and aspects in a birth chart. Mars is the ruler of Aries and Scorpio, and it is exalted in Capricorn. It is debilitated in Cancer.</p>

        <h2>Mars in the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>Mars in the First House:</strong> When Mars is in the first house, it gives the person a strong and independent personality. They are often competitive, assertive, and have a lot of energy. They may be prone to anger and impulsiveness, but they are also courageous and passionate. This placement of Mars can also indicate a person who is athletic or has a strong interest in sports. If Mars is afflicted in this house, it can indicate health problems, especially related to the head or blood.</li>
          <li><strong>Mars in the Second House:</strong> Mars in the second house can indicate a person who is financially ambitious and has a strong desire for wealth. They may be good at making money, but they can also be impulsive with their spending. This placement of Mars can also indicate a person who is outspoken and has a sharp tongue. If Mars is afflicted in this house, it can indicate problems with family relationships or financial difficulties.</li>
          <li><strong>Mars in the Third House:</strong> Mars in the third house can indicate a person who is courageous, adventurous, and has a strong will. They may be good at writing, speaking, or other forms of communication. They may also have a strong interest in travel or adventure sports. If Mars is afflicted in this house, it can indicate problems with siblings or neighbors, or difficulties with communication.</li>
          <li><strong>Mars in the Fourth House:</strong> Mars in the fourth house can indicate a person who is patriotic and has a strong attachment to their home and family. They may be good at real estate or property management. They may also have a strong interest in gardening or farming. If Mars is afflicted in this house, it can indicate problems with family relationships or domestic disputes.</li>
          <li><strong>Mars in the Fifth House:</strong> Mars in the fifth house can indicate a person who is creative, passionate, and has a strong desire for children. They may be good at sports, acting, or other forms of creative expression. They may also have a strong interest in romance and relationships. If Mars is afflicted in this house, it can indicate problems with children or difficulties with romantic relationships.</li>
          <li><strong>Mars in the Sixth House:</strong> Mars in the sixth house can indicate a person who is hardworking, disciplined, and has a strong sense of duty. They may be good at medicine, law, or other professions that require attention to detail. They may also have a strong interest in health and fitness. If Mars is afflicted in this house, it can indicate health problems, legal problems, or conflicts with coworkers.</li>
          <li><strong>Mars in the Seventh House:</strong> Mars in the seventh house can indicate a person who is passionate, assertive, and has a strong desire for partnership. They may be good at business or other forms of collaboration. They may also have a strong interest in sex and sexuality. If Mars is afflicted in this house, it can indicate problems with relationships or conflicts with partners.</li>
          <li><strong>Mars in the Eighth House:</strong> Mars in the eighth house can indicate a person who is interested in the occult, mystery, and the unknown. They may be good at research or investigation. They may also have a strong interest in sex and sexuality. If Mars is afflicted in this house, it can indicate financial problems, health problems, or a sudden and unexpected death.</li>
          <li><strong>Mars in the Ninth House:</strong> Mars in the ninth house can indicate a person who is adventurous, philosophical, and has a strong desire for travel. They may be good at teaching, writing, or other forms of intellectual expression. They may also have a strong interest in religion or spirituality. If Mars is afflicted in this house, it can indicate problems with father figures or difficulties with long-distance travel.</li>
          <li><strong>Mars in the Tenth House:</strong> Mars in the tenth house can indicate a person who is ambitious, driven, and has a strong desire for success. They may be good at business, politics, or other professions that require leadership skills. They may also have a strong interest in sports or competition. If Mars is afflicted in this house, it can indicate problems with career or difficulties with authority figures.</li>
          <li><strong>Mars in the Eleventh House:</strong> Mars in the eleventh house can indicate a person who is social, outgoing, and has a strong desire for friendship. They may be good at networking or building relationships. They may also have a strong interest in social causes or activism. If Mars is afflicted in this house, it can indicate problems with friends or difficulties with group activities.</li>
          <li><strong>Mars in the Twelfth House:</strong> Mars in the twelfth house can indicate a person who is spiritual, introspective, and has a strong desire for solitude. They may be good at meditation, yoga, or other forms of spiritual practice. They may also have a strong interest in foreign cultures or travel. If Mars is afflicted in this house, it can indicate mental health problems, addiction, or a loss of energy and motivation.</li>
        </ul>

        <h2>Mars in the Twelve Signs</h2>
        <ul className="enlarged-text">
          <li><strong>Mars in Aries:</strong> Mars is the ruler of Aries, so it is very comfortable and powerful in this sign. This placement can indicate a person who is courageous, assertive, and passionate. They may be competitive and have a strong desire to win. They may also be impulsive and quick to anger. This is an excellent placement for athletes, soldiers, and entrepreneurs. However, if Mars is afflicted, it can indicate accidents, injuries, and violence.</li>
          <li><strong>Mars in Taurus:</strong> Mars is in the sign of its friend, Venus, in Taurus. This placement can indicate a person who is sensual, passionate, and has a strong desire for material comforts. They may be good at making money and accumulating wealth. They may also be stubborn and possessive. If Mars is afflicted, it can indicate financial problems or conflicts in relationships.</li>
          <li><strong>Mars in Gemini:</strong> Mars in Gemini indicates a person who is intelligent, curious, and has a lot of energy. They may be good at communication, writing, or other forms of intellectual expression. They may also have a strong interest in travel or adventure. If Mars is afflicted, it can indicate problems with siblings or neighbors, or difficulties with communication.</li>
          <li><strong>Mars in Cancer:</strong> Mars is debilitated in Cancer, the sign of its fall. This placement can indicate a person who is emotionally sensitive, moody, and insecure. They may be prone to anger and outbursts, but they are also compassionate and caring. If Mars is afflicted, it can indicate problems with family relationships or domestic disputes.</li>
          <li><strong>Mars in Leo:</strong> Mars is in the sign of its friend, the Sun, in Leo. This placement can indicate a person who is confident, creative, and has a strong desire for recognition. They may be good at acting, performing, or other forms of creative expression. They may also have a strong interest in leadership and politics. If Mars is afflicted, it can indicate problems with ego or a tendency towards arrogance.</li>
          <li><strong>Mars in Virgo:</strong> Mars is in the sign of its enemy, Mercury, in Virgo. This placement can indicate a person who is hardworking, detail-oriented, and has a strong sense of duty. They may be good at medicine, law, or other professions that require attention to detail. They may also have a strong interest in health and fitness. If Mars is afflicted, it can indicate health problems, work-related stress, or conflicts with coworkers.</li>
          <li><strong>Mars in Libra:</strong> Mars is in the sign of its enemy, Venus, in Libra. This placement can indicate a person who is charming, diplomatic, and has a strong desire for harmony. They may be good at relationships, negotiation, or other forms of social interaction. They may also have a strong interest in art and beauty. If Mars is afflicted, it can indicate problems with relationships or a tendency towards passive-aggressiveness.</li>
          <li><strong>Mars in Scorpio:</strong> Mars is the ruler of Scorpio, so it is very comfortable and powerful in this sign. This placement can indicate a person who is passionate, intense, and has a strong will. They may be secretive and mysterious, but they are also loyal and protective. If Mars is afflicted, it can indicate problems with jealousy, obsession, or a tendency towards violence.</li>
          <li><strong>Mars in Sagittarius:</strong> Mars is in the sign of its friend, Jupiter, in Sagittarius. This placement can indicate a person who is adventurous, optimistic, and has a strong desire for knowledge. They may be good at teaching, writing, or other forms of intellectual expression. They may also have a strong interest in religion or spirituality. If Mars is afflicted, it can indicate problems with overconfidence or a tendency towards recklessness.</li>
          <li><strong>Mars in Capricorn:</strong> Mars is exalted in Capricorn, the sign of its exaltation. This placement can indicate a person who is ambitious, disciplined, and has a strong work ethic. They may be good at business, politics, or other professions that require leadership skills. They may also have a strong interest in achievement and success. If Mars is afflicted, it can indicate problems with workaholism or a tendency towards ruthlessness.</li>
          <li><strong>Mars in Aquarius:</strong> Mars is in the sign of its friend, Saturn, in Aquarius. This placement can indicate a person who is independent, rebellious, and has a strong desire for freedom. They may be good at science, technology, or other fields that require innovation. They may also have a strong interest in social causes or activism. If Mars is afflicted, it can indicate problems with rebelliousness or a tendency towards isolation.</li>
          <li><strong>Mars in Pisces:</strong> Mars is in the sign of its friend, Jupiter, in Pisces. This placement can indicate a person who is compassionate, intuitive, and has a strong desire to help others. They may be good at art, music, or other forms of creative expression. They may also have a strong interest in spirituality or mysticism. If Mars is afflicted, it can indicate problems with self-doubt or a tendency towards escapism.</li>
        </ul>
      </section>
    </main>
  );
}

export default Mars;
