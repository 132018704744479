import React, { useEffect } from 'react';
import '../App.css';

function Ketu() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="ketu">
        <h1>Ketu in Vedic Astrology</h1>
        
        <p>Ketu, also known as the South Node of the Moon, is another shadow planet in Vedic astrology. It represents the tail of the dragon, with Rahu (the North Node) representing the head. Unlike Rahu, which symbolizes worldly desires, Ketu is associated with spirituality, detachment, and liberation. It is considered a moksha-karaka (indicator of liberation) in the natal chart.</p>

        <h2>Significance of Ketu:</h2>
        <ul className="enlarged-text">
          <li><strong>Spirituality and Enlightenment:</strong> Ketu is linked to spiritual wisdom and the quest for liberation. It encourages individuals to detach from materialism and seek higher truths.</li>
          <li><strong>Past Life and Karma:</strong> Ketu represents past life experiences and karmic influences. It brings forth lessons that the soul has already learned, encouraging a move away from these areas.</li>
          <li><strong>Mysticism and Intuition:</strong> Known for its mystical and intuitive nature, Ketu enhances psychic abilities, intuition, and interest in occult sciences.</li>
          <li><strong>Detachment and Isolation:</strong> Ketu often causes a sense of detachment or disinterest in the worldly affairs represented by its house placement, promoting a focus on the inner self.</li>
        </ul>

        <h2>Ketu in the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>1st House (Ascendant):</strong>
            <ul>
              <li><strong>Personality:</strong> Individuals may appear detached and introspective. They often seek deeper meaning in life and are not overly concerned with external appearances.</li>
              <li><strong>Challenges:</strong> There may be struggles with self-identity and physical vitality.</li>
            </ul>
          </li>
          <li><strong>2nd House:</strong>
            <ul>
              <li><strong>Wealth and Speech:</strong> This placement can lead to a disinterest in accumulating wealth or possessions. Individuals may have a unique or unconventional way of speaking.</li>
              <li><strong>Challenges:</strong> Financial instability and strained family relationships can be issues.</li>
            </ul>
          </li>
          <li><strong>3rd House:</strong>
            <ul>
              <li><strong>Communication and Siblings:</strong> Ketu enhances intuition and introspective communication. Relationships with siblings might be unusual or detached.</li>
              <li><strong>Challenges:</strong> There may be difficulties in expressing oneself clearly or taking immediate actions.</li>
            </ul>
          </li>
          <li><strong>4th House:</strong>
            <ul>
              <li><strong>Home and Emotions:</strong> Individuals may feel a sense of detachment from their home environment and seek peace in solitude or spiritual practices.</li>
              <li><strong>Challenges:</strong> Emotional instability and issues related to mother or home life can be prominent.</li>
            </ul>
          </li>
          <li><strong>5th House:</strong>
            <ul>
              <li><strong>Creativity and Children:</strong> This placement can bring unique creative talents and a deep interest in spirituality. Relationships with children may be distant or unconventional.</li>
              <li><strong>Challenges:</strong> There might be difficulties in romantic relationships and speculative investments.</li>
            </ul>
          </li>
          <li><strong>6th House:</strong>
            <ul>
              <li><strong>Health and Service:</strong> Ketu here promotes service to others and can make individuals hardworking in a spiritual or healing profession.</li>
              <li><strong>Challenges:</strong> Health issues and conflicts with colleagues or employees can be a concern.</li>
            </ul>
          </li>
          <li><strong>7th House:</strong>
            <ul>
              <li><strong>Partnerships and Marriage:</strong> Individuals with Ketu in the 7th house may seek spiritual or unconventional partners. Relationships might lack traditional commitment.</li>
              <li><strong>Challenges:</strong> There can be instability in partnerships and potential issues with trust and commitment.</li>
            </ul>
          </li>
          <li><strong>8th House:</strong>
            <ul>
              <li><strong>Transformation and Secrets:</strong> This placement brings a deep interest in occult sciences, mysteries, and transformative experiences.</li>
              <li><strong>Challenges:</strong> It can lead to sudden changes, unexpected inheritances, or issues related to shared resources.</li>
            </ul>
          </li>
          <li><strong>9th House:</strong>
            <ul>
              <li><strong>Philosophy and Travel:</strong> Ketu here encourages a quest for spiritual knowledge and unconventional belief systems. There may be frequent travels or interest in foreign cultures.</li>
              <li><strong>Challenges:</strong> Conflicts with traditional beliefs and difficulties in higher education or long-distance travel can occur.</li>
            </ul>
          </li>
          <li><strong>10th House:</strong>
            <ul>
              <li><strong>Career and Public Image:</strong> This placement often leads to a career in spiritual or unconventional fields. Individuals may prefer to work behind the scenes.</li>
              <li><strong>Challenges:</strong> Work-related stress and potential public scandals or controversies can be issues.</li>
            </ul>
          </li>
          <li><strong>11th House:</strong>
            <ul>
              <li><strong>Friendships and Goals:</strong> Ketu enhances networking abilities and brings influential friends. It helps in achieving large-scale goals and ambitions.</li>
              <li><strong>Challenges:</strong> There might be conflicts within social circles and unrealistic aspirations.</li>
            </ul>
          </li>
          <li><strong>12th House:</strong>
            <ul>
              <li><strong>Spirituality and Isolation:</strong> Individuals may have a strong inclination towards spirituality and the metaphysical. They might prefer solitude or work in isolated environments.</li>
              <li><strong>Challenges:</strong> There can be issues related to escapism, addiction, or hidden enemies.</li>
            </ul>
          </li>
        </ul>

        <h2>Ketu in the Twelve Signs</h2>
        <ul className="enlarged-text">
          <li><strong>Ketu in Aries:</strong>
            <ul>
              <li><strong>Traits:</strong> Bold, adventurous, and pioneering.</li>
              <li><strong>Challenges:</strong> Impulsiveness and aggression.</li>
            </ul>
          </li>
          <li><strong>Ketu in Taurus:</strong>
            <ul>
              <li><strong>Traits:</strong> Materialistic, determined, and pleasure-seeking.</li>
              <li><strong>Challenges:</strong> Overindulgence and possessiveness.</li>
            </ul>
          </li>
          <li><strong>Ketu in Gemini:</strong>
            <ul>
              <li><strong>Traits:</strong> Communicative, intelligent, and adaptable.</li>
              <li><strong>Challenges:</strong> Restlessness and superficiality.</li>
            </ul>
          </li>
          <li><strong>Ketu in Cancer:</strong>
            <ul>
              <li><strong>Traits:</strong> Emotional, nurturing, and intuitive.</li>
              <li><strong>Challenges:</strong> Emotional instability and dependency.</li>
            </ul>
          </li>
          <li><strong>Ketu in Leo:</strong>
            <ul>
              <li><strong>Traits:</strong> Creative, confident, and leadership-oriented.</li>
              <li><strong>Challenges:</strong> Egoism and arrogance.</li>
            </ul>
          </li>
          <li><strong>Ketu in Virgo:</strong>
            <ul>
              <li><strong>Traits:</strong> Analytical, meticulous, and health-conscious.</li>
              <li><strong>Challenges:</strong> Overcritical and perfectionist tendencies.</li>
            </ul>
          </li>
          <li><strong>Ketu in Libra:</strong>
            <ul>
              <li><strong>Traits:</strong> Diplomatic, charming, and relationship-focused.</li>
              <li><strong>Challenges:</strong> Indecisiveness and people-pleasing.</li>
            </ul>
          </li>
          <li><strong>Ketu in Scorpio:</strong>
            <ul>
              <li><strong>Traits:</strong> Intense, transformative, and secretive.</li>
              <li><strong>Challenges:</strong> Obsession and manipulation.</li>
            </ul>
          </li>
          <li><strong>Ketu in Sagittarius:</strong>
            <ul>
              <li><strong>Traits:</strong> Philosophical, adventurous, and freedom-loving.</li>
              <li><strong>Challenges:</strong> Dogmatism and impracticality.</li>
            </ul>
          </li>
          <li><strong>Ketu in Capricorn:</strong>
            <ul>
              <li><strong>Traits:</strong> Ambitious, disciplined, and pragmatic.</li>
              <li><strong>Challenges:</strong> Ruthlessness and materialism.</li>
            </ul>
          </li>
          <li><strong>Ketu in Aquarius:</strong>
            <ul>
              <li><strong>Traits:</strong> Innovative, humanitarian, and eccentric.</li>
              <li><strong>Challenges:</strong> Detachment and unpredictability.</li>
            </ul>
          </li>
          <li><strong>Ketu in Pisces:</strong>
            <ul>
              <li><strong>Traits:</strong> Imaginative, spiritual, and compassionate.</li>
              <li><strong>Challenges:</strong> Escapism and lack of boundaries.</li>
            </ul>
          </li>
        </ul>
      </section>
    </main>
  );
}

export default Ketu;
