import React, { useEffect } from 'react';
import '../App.css';

function PlanetaryStrength() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Understanding Planetary Strengths in Vedic Astrology</h1>

                <p>The placement of planets in different signs significantly impacts their strength and effectiveness in a birth chart. Understanding these placements is crucial for accurate astrological interpretation. This guide covers three main categories of planetary strength:</p>

                <ol className="enlarged-text">
                    <li>Exaltation and Debilitation</li>
                    <li>Own Sign and Opposite Sign Placements</li>
                    <li>Moolatrikona Placements</li>
                </ol>

                <h2>Exaltation and Debilitation</h2>

                <p>In Vedic Astrology, each planet has specific signs where it expresses its energy in the most elevated way (exaltation) or experiences its greatest weakness (debilitation). When a planet is in its exaltation sign, it manifests its highest potential and positive qualities with great intensity. However, the actual results still depend on other factors in the chart.</p>

                <h3>Understanding Exaltation</h3>
                <ul className="enlarged-text">
                    <li>An exalted planet is at its strongest possible position</li>
                    <li>Even stronger than a planet in its own sign</li>
                    <li>Indicates good karma from past lives</li>
                    <li>Allows the planet to express its highest qualities</li>
                    <li>Generally brings positive results during its dasha periods</li>
                    <li>Can be affected by retrograde status or afflictions</li>
                </ul>

                <h3>Understanding Debilitation</h3>
                <ul className="enlarged-text">
                    <li>Occurs when a planet is in the sign opposite to its exaltation</li>
                    <li>Creates a sense of frustration and difficulty</li>
                    <li>Can manifest in two ways:
                        <ol>
                            <li>Negative expression: unethical behavior, selfishness</li>
                            <li>Positive transformation: growth through patience and hard work</li>
                        </ol>
                    </li>
                    <li>Requires overcoming selfishness to manifest positive results</li>
                </ul>

                <h3>Planetary Exaltation and Debilitation Degrees</h3>
                <img src="/planetary_ex_db_v2.png" alt="Planetary Exaltation and Debilitation Degrees" style={{ width: '55%', marginTop: '0px', marginBottom: '20px' }} />

                <h2>Effects in Each Sign</h2>

                <h3>Sun</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Aries: Gains confidence, pioneering spirit</li>
                    <li>Debilitated in Libra: Struggles with leadership, must compromise</li>
                </ul>

                <h3>Moon</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Taurus: Feels secure, good for family and business</li>
                    <li>Debilitated in Scorpio: Experiences anxiety, trust issues</li>
                </ul>

                <h3>Mercury</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Virgo: Gains technical ability and precision</li>
                    <li>Debilitated in Pisces: Experiences confusion and lack of focus</li>
                </ul>

                <h3>Venus</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Pisces: Achieves universal love, highest expression</li>
                    <li>Debilitated in Virgo: Becomes overly critical, never satisfied</li>
                </ul>

                <h3>Mars</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Capricorn: Gains discipline, strategic strength</li>
                    <li>Debilitated in Cancer: Struggles with emotions, feels overwhelmed</li>
                </ul>

                <h3>Jupiter</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Cancer: Brings prosperity, family happiness</li>
                    <li>Debilitated in Capricorn: Experiences pessimism</li>
                </ul>

                <h3>Saturn</h3>
                <ul className="enlarged-text">
                    <li>Exalted in Libra: Achieves perfect justice, patience</li>
                    <li>Debilitated in Aries: Faces frustration, impulsive actions</li>
                </ul>

                <h3>Rahu and Ketu</h3>
                <ul className="enlarged-text">
                    <li>Rahu exalted in Gemini: Gains communication skills</li>
                    <li>Rahu debilitated in Sagittarius: Struggles with authority</li>
                    <li>Ketu exalted in Sagittarius: Attains spiritual wisdom</li>
                    <li>Ketu debilitated in Gemini: Faces communication challenges</li>
                </ul>

                <h2>Own and Opposite Sign Placements</h2>

                <p>When a planet occupies its own sign, it expresses its fundamental nature with confidence and ease. The planet not only benefits the house it occupies but also strengthens the houses it rules. In contrast, when a planet is in the sign opposite its own sign, it often faces challenges in expressing its qualities effectively.</p>

                <h3>Understanding Own Sign Strength</h3>
                <ul className="enlarged-text">
                    <li>Second strongest position after exaltation</li>
                    <li>Planet feels at home and comfortable</li>
                    <li>Can express its natural qualities freely</li>
                </ul>

                <h3>Planetary Own Signs and Their Effects</h3>
                <img src="/planet_own_far.png" alt="Planetary Own Signs and Their Effects" style={{ width: '90%', marginTop: '0px', marginBottom: '20px' }} />

                <h2>Moolatrikona Placements</h2>

                <p>The concept of Moolatrikona is unique to Vedic Astrology and represents a special portion of a planet's own sign where it expresses its energy in a particularly pure and fundamental way. While similar to owning a sign, Moolatrikona placement carries additional significance as it connects to the planet's most essential qualities.</p>

                <p>When planets occupy their Moolatrikona degrees, they express their core nature with particular clarity and strength. This placement ensures that the planet's significations manifest in a more consistent and reliable manner than they might in other positions, even within their own signs.</p>

                <h3>Understanding Moolatrikona</h3>
                <ul className="enlarged-text">
                    <li>Represents the planet's office or workplace</li>
                    <li>Indicates efficiency and task completion</li>
                    <li>Different from own sign placement</li>
                    <li>Opposite signs create distraction and inefficiency</li>
                </ul>

                <h3>Planetary Moolatrikona Positions</h3>
                <img src="/planet_moola.png" alt="Planetary Moolatrikona Positions" style={{ width: '90%', marginTop: '0px', marginBottom: '20px' }} />

                <h3>Important Notes for Interpretation</h3>
                <ol className="enlarged-text">
                    <li>Exaltation and debilitation cannot be cancelled completely</li>
                    <li>Effects can be mitigated or enhanced by other factors</li>
                    <li>All placements represent past karma that must manifest</li>
                    <li>Retrograde status can modify the expression of planetary strength</li>
                    <li>House placement affects how the planetary strength manifests</li>
                    <li>Complete chart analysis is necessary for accurate interpretation</li>
                </ol><br></br>

                <h1>Additional Planetary Strength Factors</h1>

                <p>Continuing with what we have discussed above, the strength of planets is also determined by additional factors that we must understand. There are especially four crucial aspects:</p>

                <ol className="enlarged-text">
                    <li>Directional Strength (Dig Bala)</li>
                    <li>Strength by Degrees</li>
                    <li>Retrograde Planets</li>
                    <li>Moon Strength Assessment</li>
                </ol>

                <h2>Understanding Dig Bala (Directional Strength)</h2>

                <h3>What is Dig Bala?</h3>
                <ul className="enlarged-text">
                    <li>Dig = Direction</li>
                    <li>Bala = Strength</li>
                    <li>Occurs only in Kendra houses (1, 4, 7, 10)</li>
                    <li>Indicates planets with clear purpose and direction</li>
                    <li>Helps achieve goals and attain success</li>
                </ul>

                <p>Dig Bala is one of the most important sources of planetary strength in Vedic Astrology. Each planet has certain directional preferences where it becomes naturally strong. This strength is based on the cardinal directions and their corresponding houses in the birth chart. In Vedic astrology, the 10th house represents the south, the 4th house represents the north, the 7th house represents the west, and the 1st house (Ascendant) represents the east.</p>

                <h3>Planetary Directional Strength Positions</h3>
                <img src="/planet_dir_strength.png" alt="Planetary Directional Strength Positions" style={{ width: '90%', marginTop: '0px', marginBottom: '0px' }} />

                <p>*Note: Ketu is not traditionally given Dig Bala, though some astrologers associate it with the 10th house.</p>

                <h3>Zero Dig Bala (Directional Weakness)</h3>

                <p>Planets become weak when placed in houses opposite to their Dig Bala positions:</p>

                <img src="/planet_dir_weakness.png" alt="Zero Dig Bala Positions" style={{ width: '75%', marginTop: '5px', marginBottom: '20px' }} />

                <h2>Strength by Degrees</h2>

                <p>Earlier, we looked at a planet's exaltation and debilitation degrees. However, there are more degrees that we must now look at. The first and last couple of degrees of any sign are considered sensitive points. A planet in these degrees operates in a more complex way, often becoming unstable or unpredictable in its effects.</p>

                <h3>Critical Degrees</h3>

                <p>Planets become weakest in:</p>
                <ul className="enlarged-text">
                    <li>First 2 degrees (0-1°) of any sign</li>
                    <li>Last 2 degrees (28-29°) of any sign</li>
                </ul>

                <h3>Pattern by Sign Gender</h3>

                <ol className="enlarged-text">
                    <li>Male Signs (Odd-numbered):
                        <ul>
                            <li>First 2° = Childlike state</li>
                            <li>Last 2° = Elderly state</li>
                        </ul>
                    </li>
                    <li>Female Signs (Even-numbered):
                        <ul>
                            <li>First 2° = Elderly state</li>
                            <li>Last 2° = Childlike state</li>
                        </ul>
                    </li>
                </ol>

                <h3>Effects of Critical Degrees</h3>
                <ul className="enlarged-text">
                    <li>Creates vulnerability</li>
                    <li>Introduces chaos or confusion</li>
                    <li>May require guidance or support</li>
                    <li>Can make the planet impulsive</li>
                    <li>Affects houses ruled by the planet</li>
                </ul>

                <h3>Gandanta</h3>

                <p>Gandanta is a concept in Vedic Astrology that deals with sensitive junctional points in the zodiac. These points occur specifically at the transition between water and fire signs. In Vedic astrology, when a planet is positioned at either the very beginning (0 degrees) or the very end (29 degrees) of any sign, it experiences a weakening effect. However, this weakening becomes particularly pronounced when it occurs at the junction between water and fire signs - a condition known as Gandanta.</p>

                <p>What makes Gandanta especially significant is that these points represent not just the meeting of signs with contrasting elements (water and fire), but they also coincide with the junction points between Nakshatras. A planet is considered to be in a Gandanta zone when it falls between 26 degrees 40 minutes of a water sign and 3 degrees 20 minutes of the following fire sign. The closer a planet is positioned to the exact conjunction point between these signs, the more pronounced the weakening effect becomes.</p>

                <h2>Retrograde Planets</h2>

                <p>Retrograde motion is a fascinating phenomenon in Vedic Astrology where planets appear to move backward from Earth's perspective. When a planet goes retrograde, its energy becomes more internalized and introspective. This apparent backward motion creates a unique situation where the planet's energy is redirected inward rather than expressing itself in the usual outward manner. During retrograde periods, planets often compel us to revisit, reassess, and reevaluate matters related to their significations. For instance, when Mercury retrogrades, it can lead to deep contemplation and revision of past decisions, while Jupiter retrograde might cause us to reconsider our philosophical beliefs and ethical standards.</p>

                <p>It's particularly noteworthy that retrograde planets have a strong connection to our past karma. They often indicate unresolved issues from previous lives that need attention in this lifetime. The effects of retrogression vary significantly among planets. For instance, Mercury and Venus are less affected by retrogression compared to the outer planets like Jupiter and Saturn. Mars retrograde often internalizes energy and ambition, which can either lead to powerful self-motivation or internal conflicts depending on its placement and aspects.</p>

                <h3>Key Characteristics of Retrograde Planets</h3>

                <ol className="enlarged-text">
                    <li>Increased Strength:
                        <ul>
                            <li>Much stronger than normal</li>
                            <li>Enhanced focus and persistence</li>
                            <li>Intensified significations</li>
                        </ul>
                    </li>
                    <li>Behavioral Patterns:
                        <ul>
                            <li>Creates repeated patterns</li>
                            <li>Requires multiple attempts</li>
                            <li>Focus on perfection</li>
                            <li>Can be exhausting</li>
                        </ul>
                    </li>
                    <li>Special Considerations:
                        <ul>
                            <li>Focuses on opposite sign</li>
                            <li>May act like it's in the opposite sign</li>
                            <li>Particularly important in exaltation/debilitation</li>
                        </ul>
                    </li>
                </ol>

                <h3>Important Rules for Retrograde Planets</h3>
                <ol className="enlarged-text">
                    <li>Sun and Moon are never retrograde</li>
                    <li>Rahu and Ketu are always retrograde</li>
                    <li>Retrograde planets in exaltation signs may act as if debilitated</li>
                    <li>Retrograde planets in debilitation signs may act as if exalted</li>
                    <li>Affects both the house occupied and houses ruled by the planet</li>
                </ol>

                <h3>Planets That Can Be Retrograde</h3>
                <ul className="enlarged-text">
                    <li>Mercury</li>
                    <li>Venus</li>
                    <li>Mars</li>
                    <li>Jupiter</li>
                    <li>Saturn</li>
                </ul>

                <h3>Important Notes for Interpretation</h3>
                <ol className="enlarged-text">
                    <li>No planetary condition completely cancels another</li>
                    <li>Exalted planets remain beneficial despite afflictions</li>
                    <li>Debilitated planets still present challenges despite good placement</li>
                    <li>Multiple factors must be considered together</li>
                    <li>Focus on working with challenging placements rather than fearing them</li>
                </ol>

                <h2>Moon Strength Assessment</h2>

                <p>The Moon's strength in Vedic Astrology is uniquely dynamic, changing throughout the lunar month in a way that's different from other planets. Rather than being consistently benefic or malefic, the Moon's nature and strength largely depend on its relationship with the Sun, specifically its phase in the lunar cycle.</p>

                <p>During the bright half of the lunar month (shukla paksha), when the Moon is waxing or moving away from the Sun, it gradually gains strength and enhances its benefic qualities. This period culminates at the Full Moon, when the Moon is exactly opposite the Sun and reaches its peak of strength and benevolence. Conversely, during the dark half (krishna paksha), when the Moon is waning or moving toward the Sun, its strength and beneficial influences gradually diminish until it reaches the New Moon phase.</p>

                <p>From a practical perspective, many astrologers consider the Moon particularly weak when it's within 72 degrees of the Sun, while it's considered especially powerful when more than 120 degrees away from the Sun. Some astrologers divide the lunar month into three distinct periods: the first ten days when the Moon has moderate strength, the middle ten days when it possesses full strength, and the final ten days when its strength progressively declines.</p>

                <p>Interestingly, while a Moon close to the Sun is generally considered unfavorable from a material perspective, it carries a different significance spiritually. In spiritual terms, this close conjunction can represent the mind (Moon) aligning with or submitting to the higher self or spirit (Sun), suggesting a potential for spiritual growth despite material challenges.</p>

                <h3>Dark Moon vs. Strong Moon</h3>
                <ul className="enlarged-text">
                    <li>New Moon (conjunction with Sun): Weakened state</li>
                    <li>Full Moon (opposition to Sun): Full strength</li>
                    <li>Weak Moon: Within 72° before or after the Sun</li>
                    <li>All other positions indicate varying degrees of strength</li>
                </ul>

                <h3>Effects of Weak Moon</h3>
                <ul className="enlarged-text">
                    <li>Increased emotional sensitivity</li>
                    <li>Difficulty controlling emotions</li>
                    <li>Vulnerability to others' emotional states</li>
                    <li>Potential relationship challenges</li>
                </ul>
            </section>
        </main>
    );
}

export default PlanetaryStrength;
