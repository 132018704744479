import React, { useEffect } from 'react';
import '../App.css';

function Home() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);
    
    return (
    <main>
        <section id="home">
        <h1>What is Vedic astrology and why explore it?</h1>

        <p>Vedic astrology, also known as "Jyotish," is an ancient system of knowledge originating in India over 5000 years ago. It is a discipline that delves into the profound connection between celestial bodies and human existence. Rooted in the Vedas, the sacred scriptures of Hinduism, Vedic astrology offers a comprehensive understanding of the universe and our place within it.</p>
        <p>Vedic astrology is based on the fundamental principle that the positions and movements of celestial bodies, such as the Sun, Moon, and planets, at the time of our birth have a significant influence on our lives. By analyzing the birth chart, a personalized map of the heavens at the moment of birth, Vedic astrologers can gain insights into various aspects of an individual's life, including personality traits, strengths, weaknesses, relationships, career path, health, and even spiritual growth.</p>
        <p>One of the key distinctions of Vedic astrology is its emphasis on the moon sign, also known as the "Janma Rashi," which is considered more important than the sun sign in Western astrology. The moon sign represents our emotions, subconscious mind, and instinctive nature. It provides valuable insights into our emotional responses, habits, and overall well-being.</p>
        <p>Vedic astrology also utilizes a system of 27 lunar mansions called "Nakshatras." These Nakshatras are specific segments of the zodiac that provide further details about an individual's personality, strengths, and challenges. Each Nakshatra is associated with a specific deity, symbol, and ruling planet, adding another layer of depth to the astrological analysis.</p>
        <p>Another unique aspect of Vedic astrology is the concept of "dashas," which are planetary periods that govern different phases of our lives. Each dasha is associated with a specific planet, and its duration varies. By understanding the current dasha, Vedic astrologers can predict the general themes and events that are likely to unfold during that period.</p>
        <p>Vedic astrology offers a wide range of applications and benefits. It can help individuals gain self-awareness, understand their strengths and weaknesses, and make informed decisions about their lives. It can also provide guidance in relationships, career choices, health matters, and spiritual development.</p>
        <p>One of the most sought-after applications of Vedic astrology is in the realm of relationships. By comparing the birth charts of two individuals, Vedic astrologers can assess their compatibility and potential for a harmonious relationship. This analysis, known as "Kundali matching," considers various factors such as the placement of planets, their aspects, and the overall energy dynamics between the charts.</p>
        <p>Vedic astrology can also shed light on an individual's career path and potential for success. By analyzing the tenth house, which represents career and profession, as well as other relevant houses and planetary combinations, Vedic astrologers can identify suitable career options, potential challenges, and periods of growth and recognition.</p>
        <p>In addition to personal readings and consultations, Vedic astrology is also used for "Muhurta," which is the selection of auspicious timings for important events such as weddings, business ventures, and even medical procedures. By aligning the planetary energies with the desired outcome, Vedic astrologers can help individuals maximize their chances of success and minimize potential obstacles.</p>
        <p>Vedic astrology is not just a predictive tool; it is also a profound system of self-discovery and personal growth. It encourages individuals to take responsibility for their actions, understand their karmic patterns, and make conscious choices that align with their higher purpose.</p>
        <p>Through remedies such as gemstone recommendations, mantra chanting, and charitable acts, Vedic astrology offers ways to mitigate the negative effects of planetary influences and enhance positive outcomes. However, it is important to note that remedies are not a quick fix or a substitute for personal effort and self-improvement.</p>
        <p>Vedic astrology is a vast and complex subject with a rich history and tradition. It is a science that requires years of study and practice to master. However, even a basic understanding of its principles can provide valuable insights and guidance in navigating the complexities of life. This is why this website exists!</p>
        <p>If you are interested in exploring the depths of Vedic astrology, there are numerous resources available, including books, websites, and online courses. There are also many websites where you can generate your own Vedic birth chart(s) for free. You will also most likely be provided with a list of all your planetary placements. This website can help you explore many aspects of your birth chart just to get started. You can also consult with a qualified Vedic astrologer who can provide personalized readings and guidance based on your birth chart.</p>
        <p>Remember, Vedic astrology is not about predicting the future with absolute certainty. It is about understanding the cosmic energies that influence our lives and using that knowledge to make informed choices, overcome challenges, and live a more fulfilling and purposeful life.</p>

        </section>
    </main>
    );
}

export default Home;
