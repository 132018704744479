import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header-container">
      <Link to="/" className="header-link">
        <div className="header-content">
          <h1 className="site-title">VedicAstro101</h1>
          <p className="site-subtitle">Vedic Astrology Insights</p>
        </div>
      </Link>
    </header>
  );
};

export default Header;
