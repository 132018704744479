import React, { useEffect } from 'react';
import '../App.css';

function Moon() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="moon">
        <h1>The Moon Sign in Vedic Astrology</h1>
        
        <p>
          The Moon is one of the most important planets in Vedic astrology. It represents the mind, emotions, and mother. The Moon is a watery planet, and it is said to be the fastest moving planet in the zodiac. This is why the Moon changes signs every 2.5 days. The Moon is also said to be a very sensitive planet, and it is easily influenced by the other planets in the chart.
        </p>
        <p>
          The Moon sign is determined by the sign that the Moon was in at the time of your birth. This sign is said to represent your emotional nature, your subconscious mind, and your instincts. It also shows how you relate to your mother and other women in your life.
        </p>
        <p>
          The Moon sign is an important factor in determining your overall personality and temperament. It can also give insights into your career, relationships, and health.
        </p>

        <h2>The Moon in the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>The Moon in the 1st House:</strong> The Moon in the 1st house makes a person very emotional and sensitive. They are often very intuitive and have a strong connection to their inner world. They may be shy and introverted, but they are also very creative and imaginative. This position of the Moon can also make a person very moody and changeable.</li>
          <li><strong>The Moon in the 2nd House:</strong> The Moon in the 2nd house indicates a person who is very attached to their family and home. They are often very traditional and value security and stability. They may be good with money, but they can also be possessive and materialistic.</li>
          <li><strong>The Moon in the 3rd House:</strong> The Moon in the 3rd house shows a person who is very communicative and expressive. They are often very curious and love to learn new things. They may be good at writing, speaking, or teaching.</li>
          <li><strong>The Moon in the 4th House:</strong> The Moon in the 4th house is a very comfortable placement for the Moon. This person is often very close to their mother and family. They value home and security, and they may be very good at cooking and taking care of others.</li>
          <li><strong>The Moon in the 5th House:</strong> The Moon in the 5th house indicates a person who is very creative and expressive. They are often very passionate and romantic. They may be good at acting, writing, or music.</li>
          <li><strong>The Moon in the 6th House:</strong> The Moon in the 6th house can be a difficult placement for the Moon. This person may be prone to anxiety and worry. They may also have health problems, especially related to the stomach or digestive system.</li>
          <li><strong>The Moon in the 7th House:</strong> The Moon in the 7th house shows a person who is very focused on relationships. They are often very charming and diplomatic. They may be good at business or law.</li>
          <li><strong>The Moon in the 8th House:</strong> The Moon in the 8th house can be a challenging placement for the Moon. This person may be prone to emotional ups and downs. They may also have a strong interest in the occult or taboo subjects.</li>
          <li><strong>The Moon in the 9th House:</strong> The Moon in the 9th house indicates a person who is very philosophical and spiritual. They are often very idealistic and have a strong sense of justice. They may be good at teaching or writing.</li>
          <li><strong>The Moon in the 10th House:</strong> The Moon in the 10th house shows a person who is very ambitious and career-oriented. They are often very successful in their chosen field. They may be good at business or politics.</li>
          <li><strong>The Moon in the 11th House:</strong> The Moon in the 11th house indicates a person who is very social and popular. They are often very friendly and outgoing. They may be good at networking or sales.</li>
          <li><strong>The Moon in the 12th House:</strong> The Moon in the 12th house can be a difficult placement for the Moon. This person may be prone to isolation and loneliness. They may also have a strong interest in spirituality or the occult.</li>
        </ul>

        <h2>The Moon in the Twelve Signs</h2>
        <ul className="enlarged-text">
          <li><strong>The Moon in Aries:</strong> The Moon in Aries is impulsive, passionate, and courageous. They are natural leaders and are always up for a challenge.</li>
          <li><strong>The Moon in Taurus:</strong> The Moon in Taurus is sensual, grounded, and practical. They are loyal and dependable, and they love the finer things in life.</li>
          <li><strong>The Moon in Gemini:</strong> The Moon in Gemini is curious, communicative, and versatile. They are quick learners and are always up for a new adventure.</li>
          <li><strong>The Moon in Cancer:</strong> The Moon in Cancer is nurturing, sensitive, and emotional. They are very family-oriented and value home and security.</li>
          <li><strong>The Moon in Leo:</strong> The Moon in Leo is creative, dramatic, and passionate. They are natural performers and love to be in the spotlight.</li>
          <li><strong>The Moon in Virgo:</strong> The Moon in Virgo is analytical, organized, and detail-oriented. They are perfectionists and are always striving to improve themselves.</li>
          <li><strong>The Moon in Libra:</strong> The Moon in Libra is charming, diplomatic, and social. They are peacemakers and are always looking for balance and harmony.</li>
          <li><strong>The Moon in Scorpio:</strong> The Moon in Scorpio is intense, passionate, and secretive. They are very loyal and protective of their loved ones.</li>
          <li><strong>The Moon in Sagittarius:</strong> The Moon in Sagittarius is adventurous, optimistic, and philosophical. They are always looking for new experiences and knowledge.</li>
          <li><strong>The Moon in Capricorn:</strong> The Moon in Capricorn is ambitious, disciplined, and responsible. They are hard workers and are always striving to achieve their goals.</li>
          <li><strong>The Moon in Aquarius:</strong> The Moon in Aquarius is independent, unconventional, and humanitarian. They are visionaries and are always looking for ways to make the world a better place.</li>
          <li><strong>The Moon in Pisces:</strong> The Moon in Pisces is compassionate, intuitive, and imaginative. They are very spiritual and have a strong connection to their inner world.</li>
        </ul>
      </section>
    </main>
  );
}

export default Moon;
