import React from 'react';
import '../App.css';

function Privacy() {
  return (
    <main>
      <section id="privacy">
        <h1>Privacy Policy</h1>
        <p>Last updated: 12/26/2024</p>

        <h2>Introduction</h2>
        <p>VedicAstro101.com ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website.</p>

        <h2>Information We Collect</h2>
        <p>We do not collect any personal information. This website exists for educational purposes. We do not sell anything or offer any kind of paid service. However, should you choose to contact us voluntarily, you must provide your email along with the reason(s) for your contact.</p>

        <h2>Personal Information</h2>
        <p>We may collect personal information that you provide directly to us when you:</p>
        <ul>
          <li>Fill out a form on our website</li>
          <li>Contact us directly</li>
        </ul>

        <h2>Usage Data</h2>
        <p>We may also collect information on how our website is accessed and used. This usage data may include information such as your IP address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</p>

        <h2>How We Use Your Information</h2>
        <p>We may use the collected information for various purposes:</p>
        <ul>
          <li>To provide and maintain our website</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our website</li>
        </ul>

        <h2>Cookies</h2>
        <p>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

        <h2>Sharing your Information</h2>
        <p>We will not share your personal information with third parties except as necessary to provide our services or as required by law.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about our Privacy Policy, please contact us using the form available on the About/Contact page.</p>
      </section>
    </main>
  );
}

export default Privacy;
