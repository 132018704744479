import React, { useEffect } from 'react';
import '../App.css';

function Aspects() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Understanding Aspects in Vedic Astrology</h1>

                <h2>Introduction to Aspects</h2>

                <p>Aspects in Vedic Astrology represent one of the fundamental concepts that make this ancient system both unique and insightful. In Sanskrit, these aspects are called "Drishti," which literally means "to glance" or "to see." This concept suggests that planets can influence not only the house they occupy but can also cast their energy across the birth chart to affect other houses and the planets within them. Vedic astrology considers both the individual planetary aspects (Graha Drishti) and sign-based aspects (Rashi Drishti).</p>

                <p>Western astrology applies aspects by degree regardless of sign and house. This approach is problematic because it fails to consider:</p>

                <ul className="enlarged-text">
                    <li>The individual desires, motivations, and effects of each planet</li>
                    <li>The significance of aspects in relation to specific signs and houses</li>
                </ul>

                <p>Vedic astrology, on the other hand, considers these factors, leading to a more accurate and nuanced interpretation of aspects. The most basic principle of aspects in Vedic Astrology is that every planet can cast its influence on the house directly opposite it, known as the 7th house aspect. Imagine this like a flashlight beam shooting straight across the chart. When a planet aspects another house or planet, it projects its qualities and characteristics onto that location, creating a significant influence that must be considered in chart interpretation. What makes Vedic Astrology particularly fascinating is that three planets - Mars, Jupiter, and Saturn - possess special aspects beyond the standard 7th house aspect. These are known as the unique planetary aspects.</p>

                <h2>Individual Planetary Aspects (Graha Drishti)</h2>

                <p>The sight of an individual planet is known as Graha Drishti. It is what the planet sees and desires. As a planet "looks at" or "glances" at the signs ahead, it will influence those signs with its personal sight or Drishti. So, how influential are these Graha Drishtis? These aspects will be very influential during the Mahadasha period. During its Mahadasha period, that planet will be awake in your life.</p>

                <h2>Saturn's Aspects</h2>
                
                <img src="/saturn_aspect.png" alt="Saturn's Aspects Diagram" style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }} />

                <ul className="enlarged-text">
                    <li>3rd house (100% strength)</li>
                    <li>7th house (75% strength)</li>
                    <li>10th house (100% strength)</li>
                    <li>Brings restriction, difficulty, sorrow, or loss to aspected houses</li>
                </ul>

                <h2>Jupiter's Aspects</h2>
                
                <img src="/jupiter_aspect.png" alt="Jupiter's Aspects Diagram" style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }} />

                <ul className="enlarged-text">
                    <li>5th house (100% strength)</li>
                    <li>7th house (50% strength)</li>
                    <li>9th house (100% strength)</li>
                    <li>Brings expansion, opportunities, and growth</li>
                </ul>

                <h2>Mars' Aspects</h2>
                
                <img src="/mars_aspect.png" alt="Mars' Aspects Diagram" style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }} />

                <ul className="enlarged-text">
                    <li>4th house (100% strength)</li>
                    <li>7th house (25% strength)</li>
                    <li>8th house (100% strength)</li>
                    <li>Brings pressure, control, courage, and potential aggression</li>
                </ul>

                <h2>Faster Moving Planets</h2>
                
                <img src="/faster_aspects.png" alt="Faster Moving Planets Aspects Diagram" style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }} />

                <p>Sun, Moon, Mercury, and Venus all aspect the 7th house from themselves (100% strength). This is their only aspect. Each of these four planets bring their unique influence to the aspected house:</p>

                <ul className="enlarged-text">
                    <li>Sun 7th Aspect: Brings leadership, ego, confidence, and potential power struggles or a need for autonomy into the aspected house.</li>
                    <li>Moon 7th Aspect: Injects emotional sensitivity, nurturing, and possible fluctuations. Emphasizes emotional bonding and personal security around that house.</li>
                    <li>Mercury 7th Aspect: Highlights communication, intellect, logic, and negotiation, fostering a more analytical and curious approach to the aspected house.</li>
                    <li>Venus 7th Aspect: Infuses harmony, love, and aesthetic sense, boosting partnership and creative enjoyment in that house.</li>
                </ul>

                <h2>Rahu's Aspects</h2>
                
                <img src="/rahu_aspect.png" alt="Rahu's Aspects Diagram" style={{ width: '60%', marginTop: '10px', marginBottom: '10px' }} />

                <ul className="enlarged-text">
                    <li>2nd house (100% strength)</li>
                    <li>5th house (75% strength)</li>
                    <li>7th house (100% strength)</li>
                    <li>9th house (75% strength)</li>
                </ul>

                <p>Rahu causes all kinds of problems with the signs and planets it aspects. However, it should be noted that some astrologers consider the nodes (Rahu and Ketu) as not having any aspects at all.</p>

                <h2>Ketu's Aspects</h2>

                <p>Ketu is not considered to have drishti (sight aspects). It is a headless planet after all. However, some astrologers do consider Ketu as having 5th, 7th, and 9th house aspects at least to some degree. Opinions on this differ greatly. Regardless, Ketu still has Rashi aspects.</p>

                <h2>Mutual Aspects</h2>

                <p>Another concept in Vedic Astrology is that of mutual aspects, where two planets aspect each other simultaneously. These mutual aspects can significantly alter the way both planets express their energies in the chart. There is a complex mix of planetary energies in such cases and interpretations of these shared aspects become more challenging. It depends on the planets and their particular aspect involved.</p>

                <p>When two planets fully aspect each other, this creates a special relationship between the planets called "Sambandha," or mutual/shared connection. However, it should be noted that the term "Sambandha" is also used to describe other types of planetary connections. For beginners learning Vedic Astrology, it's recommended to first master the understanding of full aspects before delving into the subtleties of partial aspects or specialized aspect systems.</p>

                <h2>Sign-Based Aspects (Rashi Drishti)</h2>

                <h3>Classification of Signs:</h3>

                <ol className="enlarged-text">
                    <li>Cardinal Signs (Chara):
                        <ul>
                            <li>Aries</li>
                            <li>Cancer</li>
                            <li>Libra</li>
                            <li>Capricorn</li>
                        </ul>
                    </li>
                    <li>Fixed Signs (Sthira):
                        <ul>
                            <li>Taurus</li>
                            <li>Leo</li>
                            <li>Scorpio</li>
                            <li>Aquarius</li>
                        </ul>
                    </li>
                    <li>Dual/Mutable Signs:
                        <ul>
                            <li>Gemini</li>
                            <li>Virgo</li>
                            <li>Sagittarius</li>
                            <li>Pisces</li>
                        </ul>
                    </li>
                </ol>

                <h3>Rules for Rashi Aspects:</h3>
                <ol className="enlarged-text">
                    <li>Cardinal Signs aspect Fixed Signs except the adjacent one</li>
                    <li>Fixed Signs aspect Cardinal Signs except the adjacent one</li>
                    <li>Dual Signs aspect all other Dual Signs</li>
                </ol>

                <h3>Example 1</h3>
                
                <img src="/dristhi_eg1.png" alt="Rashi Aspects Example 1" style={{ width: '60%', marginTop: '0px', marginBottom: '10px' }} />

                <p>In the above example, the cardinal signs are listed in red, and the fixed signs are listed in green. We will apply the rules for the Rashi aspects. Let's look at the cardinal sign Aries as an example. Aries will not aspect Taurus because it is right next to it. However, Aries will aspect Leo, Scorpio, and Aquarius. Next, let's look at the fixed sign Aquarius. Aquarius will not aspect Capricorn because it is right next to it. However, Aquarius will aspect, Aries, Cancer, and Libra.</p>

                <h3>Example 2</h3>
                
                <img src="/dristhi_eg2.png" alt="Rashi Aspects Example 2" style={{ width: '60%', marginTop: '0px', marginBottom: '10px' }} />

                <p>In the above example, the mutable signs are listed in blue. We will apply the rules for the Rashi aspects. All the planets here will influence each other without any exceptions.</p>

                <h2>Period of Influence</h2>

                <ul className="enlarged-text">
                    <li>Individual planetary aspects manifest strongly during their respective dashas</li>
                    <li>Rashi aspects are permanent and affect throughout life</li>
                </ul>

                <h2>Important Points to Remember</h2>

                <ol className="enlarged-text">
                    <li>Vedic aspects must be calculated using the Sidereal zodiac</li>
                    <li>Aspects reveal karmic patterns and life events</li>
                    <li>Both planetary and sign aspects should be considered for complete analysis</li>
                    <li>The strength of aspects varies based on:
                        <ul>
                            <li>The type of aspect</li>
                            <li>The planets involved</li>
                            <li>The houses/signs involved</li>
                            <li>The current dasha period</li>
                        </ul>
                    </li>
                </ol>
            </section>
        </main>
    );
}

export default Aspects;
