import React, { useEffect } from 'react';
import '../App.css';

function Signs() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
        <section id="signs">
        <h1>Vedic Astrology Signs: An Introduction</h1>
        
        <p>
            Zodiac signs are constellations in the sky associated with star systems. Unlike Western astrology, Vedic astrology uses the sidereal zodiac, which is based on the actual positions of the constellations in the sky. The sidereal zodiac system is related to Indian culture, whereby a person’s Rasi (zodiac sign) is determined by the moon's position at birth. Each zodiac sign has specific karmas and ruling planets or Lords, which are fundamental for understanding their impact.
        </p>

        <h2>Groupings of Zodiac Signs</h2>

        <h3>Gender Division</h3>
        <p><strong>Male Signs:</strong> Aries, Gemini, Leo, Libra, Sagittarius, Aquarius</p>
        <p><strong>Female Signs:</strong> Taurus, Cancer, Virgo, Scorpio, Capricorn, Pisces</p>

        <h3>Elemental Division</h3>
        <p><strong>Fire Signs:</strong> Aries, Leo, Sagittarius – Creative, impulsive, forward-looking.</p>
        <p><strong>Earth Signs:</strong> Taurus, Virgo, Capricorn – Grounded, practical, focused on tasks.</p>
        <p><strong>Air Signs:</strong> Gemini, Libra, Aquarius – Sociable, analytical, concerned with communication.</p>
        <p><strong>Water Signs:</strong> Cancer, Scorpio, Pisces – Emotional, intuitive, passionate.</p>

        <h3>Quality Division</h3>
        <p><strong>Cardinal (Movable) Signs:</strong> Aries, Cancer, Libra, Capricorn – Adaptable, changeable.</p>
        <p><strong>Fixed Signs:</strong> Taurus, Leo, Scorpio, Aquarius – Stubborn, persistent, karmic.</p>
        <p><strong>Mutable Signs:</strong> Gemini, Virgo, Sagittarius, Pisces – Flexible, dual nature.</p>

        <h2>The 12 Signs of the Zodiac</h2>
        <p>In Vedic astrology, there are 12 zodiac signs, each representing specific characteristics, tendencies, and influences. These signs are as follows:</p>

        <h3>Aries (Mesha)</h3>
        <p><strong>Element:</strong> Fire</p>
        <p><strong>Quality:</strong> Cardinal</p>
        <p><strong>Lord:</strong> Mars</p>
        <p><strong>Characteristics:</strong> Courageous, adventurous, impulsive.</p>
        <p><strong>Body Parts:</strong> Head, brain</p>
        <p><strong>Karmas:</strong> Initiatives, accidents, impulsive actions.</p>
        <p>
            <strong>Traits:</strong> Aries individuals are known for their energy, enthusiasm, and leadership qualities. They are pioneers, often initiating new projects and ventures. However, they can also be impulsive and headstrong. Aries is the first sign of the zodiac and signifies the beginning of the astrological year. People born under this sign are natural leaders who are courageous and confident. They are enthusiastic about life and face challenges head-on. However, their impulsive nature can sometimes lead to hasty decisions. Aries individuals thrive in competitive environments and are often found in roles where they can assert their leadership skills.
        </p>

        <h3>Taurus (Vrishabha)</h3>
        <p><strong>Element:</strong> Earth</p>
        <p><strong>Quality:</strong> Fixed</p>
        <p><strong>Lords:</strong> Venus, Moon</p>
        <p><strong>Characteristics:</strong> Stubborn, seeking security, materialistic.</p>
        <p><strong>Body Parts:</strong> Face, throat</p>
        <p><strong>Karmas:</strong> Stability, security, resistance to change.</p>
        <p>
            <strong>Traits:</strong> Taurus is characterized by stability, reliability, and a love for comfort and luxury. Taurus individuals are hardworking and persistent but can sometimes be stubborn and resistant to change. Taurus is an earth sign that embodies stability and practicality. Those born under this sign are known for their perseverance and determination. They appreciate the finer things in life and seek security in their relationships and career. Tauruses are dependable and loyal but can also be resistant to change. They excel in environments where patience and a steady approach are valued.
        </p>

        <h3>Gemini (Mithuna)</h3>
        <p><strong>Element:</strong> Air</p>
        <p><strong>Quality:</strong> Mutable</p>
        <p><strong>Lord:</strong> Mercury</p>
        <p><strong>Characteristics:</strong> Intelligent, communicative, dual nature.</p>
        <p><strong>Body Parts:</strong> Hands, arms</p>
        <p><strong>Karmas:</strong> Communication, adaptability, arguments.</p>
        <p>
            <strong>Traits:</strong> Geminis are curious, adaptable, and communicative. They enjoy learning and sharing information. Their dual nature can make them versatile but also indecisive and inconsistent at times. Gemini is an air sign associated with duality and versatility. Geminis are curious and adaptable, enjoying intellectual pursuits and social interactions. Their ability to communicate effectively makes them excellent in fields that require negotiation and mediation. However, their restless nature can lead to a lack of focus. Geminis are happiest when they have variety and mental stimulation in their lives.
        </p>

        <h3>Cancer (Karka)</h3>
        <p><strong>Element:</strong> Water</p>
        <p><strong>Quality:</strong> Cardinal</p>
        <p><strong>Lord:</strong> Moon</p>
        <p><strong>Characteristics:</strong> Emotional, nurturing, protective.</p>
        <p><strong>Body Parts:</strong> Chest, heart</p>
        <p><strong>Karmas:</strong> Emotional security, nurturing, change.</p>
        <p>
            <strong>Traits:</strong> Cancer individuals are nurturing, empathetic, and protective. They value home and family. Their sensitivity can make them compassionate but also prone to moodiness and emotional vulnerability. Cancer is a water sign known for its deep emotional sensitivity. People born under this sign are nurturing and protective of their loved ones. They are intuitive and often rely on their gut feelings. Cancer individuals value their home and family and find comfort in familiar surroundings. While they are caring and empathetic, they can also be moody and overly cautious.
        </p>

        <h3>Leo (Simha)</h3>
        <p><strong>Element:</strong> Fire</p>
        <p><strong>Quality:</strong> Fixed</p>
        <p><strong>Lord:</strong> Sun</p>
        <p><strong>Characteristics:</strong> Authoritative, creative, dramatic.</p>
        <p><strong>Body Parts:</strong> Stomach, liver</p>
        <p><strong>Karmas:</strong> Leadership, creativity, authority.</p>
        <p>
            <strong>Traits:</strong> Leos are confident, ambitious, and charismatic. They thrive in the spotlight and seek recognition. While they can be generous and warm-hearted, they may also exhibit arrogance and a need for control. Leo is a fire sign that radiates confidence and charisma. Leos are natural performers who love to be in the spotlight. They are generous and enjoy sharing their success with others. Their leadership qualities make them effective in roles that require taking charge. However, their need for admiration can sometimes be perceived as arrogance. Leos thrive when they are appreciated and respected.
        </p>

        <h3>Virgo (Kanya)</h3>
        <p><strong>Element:</strong> Earth</p>
        <p><strong>Quality:</strong> Mutable</p>
        <p><strong>Lords:</strong> Mercury, Rahu</p>
        <p><strong>Characteristics:</strong> Analytical, perfectionist, technical skills.</p>
        <p><strong>Body Parts:</strong> Intestines</p>
        <p><strong>Karmas:</strong> Perfectionism, anxiety, technical skills.</p>
        <p>
            <strong>Traits:</strong> Virgo is associated with precision, practicality, and analytical thinking. Virgos are detail-oriented and strive for perfection. They can be critical and overly meticulous but also reliable and diligent. Virgo is an earth sign that emphasizes precision and attention to detail. Virgos are analytical and methodical, often excelling in tasks that require critical thinking and organization. They are practical and reliable, making them valuable team members. However, their perfectionist tendencies can lead to stress and over-criticism. Virgos find satisfaction in helping others and achieving excellence in their work.
        </p>

        <h3>Libra (Tula)</h3>
        <p><strong>Element:</strong> Air</p>
        <p><strong>Quality:</strong> Cardinal</p>
        <p><strong>Lord:</strong> Venus</p>
        <p><strong>Characteristics:</strong> Balanced, sociable, charming.</p>
        <p><strong>Body Parts:</strong> Hips, groin</p>
        <p><strong>Karmas:</strong> Relationships, legalities, charm.</p>
        <p>
            <strong>Traits:</strong> Libras are diplomatic, charming, and value harmony. They seek balance in relationships and enjoy social interactions. While they are fair-minded, they can struggle with indecisiveness and people-pleasing tendencies. Libra is an air sign that seeks balance and harmony. Libras are diplomatic and charming, with a strong sense of justice and fairness. They are skilled at resolving conflicts and fostering cooperation. Libras enjoy social interactions and are often drawn to artistic and cultural pursuits. Their tendency to avoid confrontation can sometimes lead to indecisiveness. Libras thrive in environments that value collaboration and beauty.
        </p>

        <h3>Scorpio (Vrishchika)</h3>
        <p><strong>Element:</strong> Water</p>
        <p><strong>Quality:</strong> Fixed</p>
        <p><strong>Lords:</strong> Mars, Ketu</p>
        <p><strong>Characteristics:</strong> Intense, secretive, passionate.</p>
        <p><strong>Body Parts:</strong> Genitals, private parts</p>
        <p><strong>Karmas:</strong> Intensity, secrets, transformation.</p>
        <p>
            <strong>Traits:</strong> Scorpios are intense, passionate, and resourceful. They are known for their determination and ability to navigate complex situations. However, they can also be secretive and possessive. Scorpio is a water sign known for its intensity and depth. Scorpios are passionate and determined, often delving into the mysteries of life. They are resourceful and strategic, making them effective in roles that require investigation and problem-solving. Scorpios value loyalty and are fiercely protective of their loved ones. However, their secretive nature can sometimes create misunderstandings. Scorpios excel in environments where they can explore and transform.
        </p>

        <h3>Sagittarius (Dhanu)</h3>
        <p><strong>Element:</strong> Fire</p>
        <p><strong>Quality:</strong> Mutable</p>
        <p><strong>Lord:</strong> Jupiter</p>
        <p><strong>Characteristics:</strong> Philosophical, adventurous, exploratory.</p>
        <p><strong>Body Parts:</strong> Thighs</p>
        <p><strong>Karmas:</strong> Exploration, wisdom, adventure.</p>
        <p>
            <strong>Traits:</strong> Sagittarius individuals are adventurous, optimistic, and philosophical. They have a love for exploration and knowledge. While they are enthusiastic, they may also be reckless and blunt. Sagittarius is a fire sign associated with exploration and adventure. Sagittarians are optimistic and philosophical, always seeking new experiences and knowledge. They value freedom and independence and are often found traveling or engaging in intellectual debates. Their enthusiasm can be contagious, but their bluntness can sometimes offend others. Sagittarians thrive in environments that allow them to expand their horizons and pursue their passions.
        </p>

        <h3>Capricorn (Makara)</h3>
        <p><strong>Element:</strong> Earth</p>
        <p><strong>Quality:</strong> Cardinal</p>
        <p><strong>Lord:</strong> Saturn</p>
        <p><strong>Characteristics:</strong> Ambitious, hardworking, patient.</p>
        <p><strong>Body Parts:</strong> Knees</p>
        <p><strong>Karmas:</strong> Hard work, patience, ambition.</p>
        <p>
            <strong>Traits:</strong> Capricorns are disciplined, responsible, and ambitious. They are focused on achieving their goals through hard work and perseverance. Their seriousness can sometimes make them seem detached and reserved. Capricorn is an earth sign that embodies discipline and ambition. Capricorns are hardworking and goal-oriented, often achieving success through perseverance and strategic planning. They are practical and responsible, making them reliable leaders. However, their focus on achievement can sometimes make them appear distant and overly serious. Capricorns excel in environments that reward diligence and long-term planning.
        </p>

        <h3>Aquarius (Kumbha)</h3>
        <p><strong>Element:</strong> Air</p>
        <p><strong>Quality:</strong> Fixed</p>
        <p><strong>Lords:</strong> Saturn, Rahu</p>
        <p><strong>Characteristics:</strong> Sociable, revolutionary, intellectual.</p>
        <p><strong>Body Parts:</strong> Calves</p>
        <p><strong>Karmas:</strong> Sociability, revolution, desires.</p>
        <p>
            <strong>Traits:</strong> Aquarians are innovative, independent, and humanitarian. They think outside the box and seek to improve society. While they are progressive, they can also be unpredictable and aloof. Aquarius is an air sign that represents innovation and humanitarianism. Aquarians are independent thinkers who value originality and progress. They are often involved in social causes and enjoy working towards the betterment of society. Aquarians are friendly and enjoy connecting with diverse groups of people. Their unconventional approach can sometimes be misunderstood. Aquarians thrive in environments that encourage creativity and social change.
        </p>

        <h3>Pisces (Meena)</h3>
        <p><strong>Element:</strong> Water</p>
        <p><strong>Quality:</strong> Mutable</p>
        <p><strong>Lords:</strong> Jupiter, Ketu</p>
        <p><strong>Characteristics:</strong> Spiritual, compassionate, indecisive.</p>
        <p><strong>Body Parts:</strong> Feet</p>
        <p><strong>Karmas:</strong> Spirituality, compassion, confusion.</p>
        <p>
            <strong>Traits:</strong> Pisces individuals are compassionate, artistic, and intuitive. They are deeply connected to their emotions and the spiritual realm. Their sensitivity can make them empathetic but also prone to escapism and idealism. Pisces is a water sign that symbolizes intuition and compassion. Pisceans are empathetic and artistic, often drawn to creative and spiritual pursuits. They have a deep connection to their emotions and the emotions of others. Pisces individuals are dreamers who value peace and harmony. Their sensitivity can sometimes lead to escapism. Pisceans excel in environments that nurture their creativity and allow them to help others.
        </p>
        </section>
    </main>
  );
}

export default Signs;
