import React from 'react';
import { NavLink } from 'react-router-dom';
import '../App.css';

function SideMenu() {
  return (
    <div className="side-menu">
      <nav>
        <ul>
          <li><NavLink to="/intro" activeClassName="active">Introduction</NavLink></li>
          <li><NavLink to="/ascendants" activeClassName="active">Ascendants</NavLink></li>
          <li><NavLink to="/planets" activeClassName="active">Planets</NavLink></li>
          <li><NavLink to="/houses" activeClassName="active">Houses</NavLink></li>
          <li><NavLink to="/signs" activeClassName="active">Signs</NavLink></li>
          <li><NavLink to="/nakshatras" activeClassName="active">Nakshatras</NavLink></li>
          <li><NavLink to="/moon" activeClassName="active">Moon</NavLink></li>
          <li><NavLink to="/sun" activeClassName="active">Sun</NavLink></li>
          <li><NavLink to="/mercury" activeClassName="active">Mercury</NavLink></li>
          <li><NavLink to="/mars" activeClassName="active">Mars</NavLink></li>
          <li><NavLink to="/venus" activeClassName="active">Venus</NavLink></li>
          <li><NavLink to="/jupiter" activeClassName="active">Jupiter</NavLink></li>
          <li><NavLink to="/saturn" activeClassName="active">Saturn</NavLink></li>
          <li><NavLink to="/rahu" activeClassName="active">Rahu</NavLink></li>
          <li><NavLink to="/ketu" activeClassName="active">Ketu</NavLink></li>
          <li><NavLink to="/planetary-strength" activeClassName="active">Planetary Strength</NavLink></li>
          <li><NavLink to="/relations" activeClassName="active">Planetary Relations</NavLink></li>
          <li><NavLink to="/navamsa" activeClassName="active">Navamsa</NavLink></li>
          <li><NavLink to="/conjunctions" activeClassName="active">Conjunctions</NavLink></li>
          <li><NavLink to="/aspects" activeClassName="active">Aspects</NavLink></li>
          <li><NavLink to="/retrogrades" activeClassName="active">Retrogrades</NavLink></li>
          <li><NavLink to="/yogas1" activeClassName="active">Yogas 1</NavLink></li>
          <li><NavLink to="/yogas2" activeClassName="active">Yogas 2</NavLink></li>
          <li><NavLink to="/about" activeClassName="active">About/Contact</NavLink></li>
          <li><NavLink to="/privacy" activeClassName="active">Privacy Policy</NavLink></li>
        </ul>
      </nav>
    </div>
  );
}

export default SideMenu;
