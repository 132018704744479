import React, { useEffect } from 'react';
import '../App.css';

function Yogas1() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Understanding Yogas</h1>

                <p>In Vedic Astrology, the term yoga signifies "joining" or "yoking". It refers to specific planetary combinations that generate unique effects, shaping an individual's life path. These combinations can involve conjunctions (planets in the same sign), oppositions (planets in opposite signs), or the exchange of signs between planets (Parivartan yoga). However, special care must be taken because some yogas do not involve opposition placements. Many yogas exist in Vedic Astrology, but we only focus on some of the most important ones here. Yogas offer valuable insights into an individual's strengths, challenges, and potential life path. However, a comprehensive chart analysis is essential for a complete and accurate understanding.</p>

                <h2>Raj Yoga Karaka Planets</h2>

                <p>Certain ascendants (rising signs) possess Raj Yoga Karaka planets. These planets, by virtue of ruling specific houses (Kendra and Trine houses), have the potential to bestow great fortune and success.</p>

                <img src="/yoga_karaka_planets.png" alt="Raj Yoga Karaka Planets" style={{ width: '75%', marginTop: '0px', marginBottom: '0px' }} />

                <p>* Saturn, as the lord of the Badhaka house, may not be as powerful as Rahu in this ascendant.</p>

                <h3>Key Points:</h3>
                <ul className="enlarged-text">
                    <li>Raj Yoga Karaka planets are particularly strong when placed in Kendra (1st, 4th, 7th, 10th) or Trine (1st, 5th, 9th) houses.</li>
                    <li>They exert a strong influence on any planet they conjunct.</li>
                    <li>Even in challenging houses (6th, 8th, 12th), these planets can still bring benefits, albeit with accompanying difficulties.</li>
                    <li>Most Raj Yoga Karaka planets (Rahu, Mars, Saturn) are malefics, meaning they can bring challenges that ultimately lead to growth and power.</li>
                    <li>Venus is the only benefic Raj Yoga Karaka planet, providing a more harmonious path to success.</li>
                    <li>The planetary periods (dashas) of Raj Yoga Karaka planets can bring significant upliftment and progress in life.</li>
                </ul>

                <h2>Shubh Kartari and Paap Kartari Yogas</h2>

                <p>These yogas, also known as "scissors" yogas, describe how planets influence a house by surrounding it.</p>

                <h3>Shubh Kartari Yoga</h3>
                <ul className="enlarged-text">
                    <li>Benefic planets (Mercury, Venus, Jupiter), when placed on either side of a house (not inside the house), create Shubh Kartari Yoga.</li>
                    <li>This configuration is akin to a "hug," providing blessings and protection to the house.</li>
                    <li>The Moon is considered a benefic only if it is not a dark moon (within 72 degrees of the Sun).</li>
                </ul>

                <h3>Paap Kartari Yoga</h3>
                <ul className="enlarged-text">
                    <li>Malefic planets (Sun, Mars, Saturn, Rahu, Ketu) flanking a house form Paap Kartari Yoga.</li>
                    <li>This yoga brings stress and challenges to the matters represented by the house, often due to past life karma.</li>
                    <li>However, if the malefic planets naturally favor the house they are squeezing, they can provide a positive push or motivation.</li>
                </ul>

                <h2>Planetary Yogas: Conjunctions, Oppositions, and Parivartan</h2>

                <p>Planetary yogas are formed through various interactions between planets, including:</p>
                <ul className="enlarged-text">
                    <li>Conjunctions: Planets in the same sign.</li>
                    <li>Oppositions: Planets in opposite signs.</li>
                    <li>Parivartan Yoga: Planets exchanging signs, which is considered the strongest type of yoga.</li>
                </ul>

                <h2>Key Planetary Yogas</h2>

                <h3>Budh Aditya Yoga (Sun-Mercury Conjunction)</h3>
                <ul className="enlarged-text">
                    <li>Mercury, especially when placed slightly ahead of the Sun (even within combustion range), gains special strength and wisdom.</li>
                    <li>This yoga can bestow charisma, strong communication skills, business acumen, and a knack for learning.</li>
                </ul>

                <h3>Chandra Mangala Yoga (Moon-Mars Conjunction)</h3>
                <ul className="enlarged-text">
                    <li>This yoga can grant discipline, focus, determination, and the potential for significant achievements.</li>
                    <li>The sign placement is crucial, as unfavorable signs can lead to inner conflicts.</li>
                </ul>

                <h3>Vyasa Yoga (Mercury-Jupiter Conjunction)</h3>
                <ul className="enlarged-text">
                    <li>This yoga signifies wisdom, knowledge, academic success, spiritual understanding, and the acquisition of various skills.</li>
                    <li>It often indicates the presence of a mentor or guide who imparts knowledge and support.</li>
                </ul>

                <h3>Brahma Yoga (Jupiter-Saturn Conjunction)</h3>
                <ul className="enlarged-text">
                    <li>This yoga marks the beginning of a new cycle and offers tremendous creative and business abilities.</li>
                    <li>It empowers individuals to rebuild and transform their lives.</li>
                </ul>

                <h3>Yama Yoga (Mars-Saturn Combinations)</h3>
                <ul className="enlarged-text">
                    <li>This yoga, involving the fiery Mars and the restrictive Saturn, can bring stress and a destructive tendency.</li>
                    <li>It requires learning to manage frustration and anger constructively.</li>
                    <li>Positively, it can provide strong planning and focus.</li>
                </ul>

                <h3>Guru Chandal Yoga (Jupiter-Rahu Conjunction or Parivartan)</h3>
                <ul className="enlarged-text">
                    <li>This yoga instills a strong desire for knowledge, bordering on obsession.</li>
                    <li>It can lead to unconventional paths of learning and a rebellious spirit against authority and traditional norms.</li>
                </ul>

                <h3>Mars-Rahu Combinations (Conjunction or Parivartan)</h3>
                <ul className="enlarged-text">
                    <li>These combinations signify a "war" between Mars, the general of the gods, and Rahu, the demon king.</li>
                    <li>The outcome depends on the relative strength of each planet, with Rahu often considered more deceptive and likely to win.</li>
                    <li>Requires careful consideration of one's actions as this is a powerful yoga which can cause all sorts of problems related to behavior, relationships, etc.</li>
                    <li>This yoga can manifest as daring, rebelliousness, and a strong drive for achievement, but it requires controlling anger and acting ethically.</li>
                </ul>

                <h3>Saturn-Rahu Combination (Conjunction or Parivartan)</h3>
                <ul className="enlarged-text">
                    <li>This yoga brings tension as Rahu seeks to break boundaries while Saturn prefers stability and hard work.</li>
                    <li>It can grant courage and persistence, but also constant dissatisfaction and a feeling of needing to do more.</li>
                    <li>Patience is key for individuals with this combination.</li>
                    <li>Physical concerns related to joints, skin, and arthritis may arise.</li>
                </ul>

                <h3>Saturn-Ketu Combination (Conjunction or Parivartan)</h3>
                <ul className="enlarged-text">
                    <li>This yoga can enable individuals to rise above difficult situations through focused effort and an independent spirit.</li>
                    <li>However, it can also lead to a tendency to disregard advice and act solely on one's own judgement.</li>
                </ul>

                <h3>Parampara Yoga (Jupiter-Ketu Conjunction or Parivartan)</h3>
                <ul className="enlarged-text">
                    <li>This yoga signifies spiritual discipline, the continuation of past life knowledge, and a deep yearning for wisdom.</li>
                    <li>It bestows intuitive understanding, logical thinking, and research abilities, making it favorable for astrological pursuits.</li>
                    <li>It may lead to introversion and a strong preference for solitary study.</li>
                </ul>

                <h2>Raj Yogas: Royal Giving Yogas</h2>

                <p>Raj yogas signify a rise in life, bringing status, success, resources, and power. They do not necessarily make someone a king, but they bring an elevated position in life. There are many Raj yogas, but we focus on essential ones for beginners. Raj yogas have different levels of intensity.</p>

                <h3>Lakshmi Narayan Yoga</h3>
                <p>This is a very important Raj yoga. Lakshmi is the goddess of Fortune and consort of Narayan (Vishnu). Narayan rules the Kendra houses and Lakshmi rules the Trine houses. This yoga occurs when the lord of a Kendra house (1, 4, 7, 10) is conjunct with the lord of a Trine house (1, 5, 9) in the same sign. This conjunction gives intelligence, knowledge, wealth, property, status, partnerships, and divine blessings. The yoga is effective anywhere in the chart, but it's particularly helpful when in a Kendra or Trine house. The Dasha period of the two planets involved is particularly significant.</p>

                <h3>Dharma Karmadhipati Yoga</h3>
                <p>A powerful type of Lakshmi Narayan yoga. It occurs when the lord of the 9th house (Dharma) and the 10th house (Karma) are conjunct. It shows that one's deeds are in tune with their Dharma, promoting soul growth and good karma. This is especially helpful when the conjunction is in a Kendra or Trine house.</p>

                <h2>Vipreet Raj Yoga: Overcoming Obstacles</h2>

                <p>This yoga is formed by the lords of the Dusthana houses (6, 8, 12). It gives the ability to overcome obstacles and problems. People with this yoga get success despite obstacles. This yoga occurs when the lord of the Dusthana house is in their own house or other Dusthana house. A Vipreet Raj yoga is particularly strong when these lords are in their own signs in their own houses.</p>

                <h3>Important Consideration</h3>
                <p>Even with Vipreet Raj yoga, the karmas of the 6th, 8th, and 12th houses can still cause challenges, even if the person can overcome them. These karmas may ripen later in life. This yoga is not a magical solution but gives resilience.</p>

                <h2>Neecha Bhanga Raja Yoga: Cancellation of Debilitation</h2>

                <p>Neecha means debilitated, and Bhanga means cancellation. This yoga helps one overcome the effects of a debilitated planet. It is a fairly rare yoga. This occurs when a planet is in its debilitation sign but is conjunct with an exalted planet.</p>

                <ul className="enlarged-text">
                    <li>Sun in Libra with Saturn in Libra.</li>
                    <li>Mercury in Pisces with Venus in Pisces.</li>
                    <li>Venus in Virgo with Mercury in Virgo.</li>
                    <li>Mars in Cancer with Jupiter in Cancer.</li>
                    <li>Jupiter in Capricorn with Mars in Capricorn.</li>
                    <li>Saturn in Aries with the Sun in Aries.</li>
                    <li>No Neecha Bhanga Raj yoga for Rahu or Ketu.</li>
                    <li>The yoga of Mercury and Venus is fairly common as they are often together.</li>
                </ul>

                <h2>Dhana Yogas: Wealth Giving Yogas</h2>

                <p>These yogas indicate wealth in a horoscope. The wealth houses are as follows:</p>

                <ul className="enlarged-text">
                    <li>2nd House: Possessions, money in the bank, family business, inheritance, savings, fixed assets, property.</li>
                    <li>11th House: Gains, what you earn, profit, income.</li>
                    <li>5th House: Fortune, luck, speculative gain.</li>
                    <li>9th House: Past life fortune, fortune, foreign interests.</li>
                    <li>4th House: Property, happiness, contentment, peace.</li>
                </ul>

                <p>A connection between the 1st, 5th and 9th houses is very fortunate for wealth. Also note that inheritance is shown by the 8th house, but when it is owned, it comes under the 2nd house.</p>

                <h3>How to See Dhana Yogas:</h3>
                <ul className="enlarged-text">
                    <li>Any combination of lords of the 2nd, 4th, 5th, 9th, and 11th houses together.</li>
                    <li>Conjunctions of these house lords.</li>
                    <li>Look for involvement of Lakshmi Sthana houses (1, 5, 9).</li>
                </ul>
            </section>
        </main>
    );
}

export default Yogas1;
