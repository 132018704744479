import React, { useEffect } from 'react';
import '../App.css';

function Mercury() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="mercury">
        <h1>Understanding Mercury in Vedic Astrology</h1>
        
        <p>In Vedic astrology, Mercury, often referred to as Budha, is a planet of significant importance. It is considered a neutral planet, meaning it doesn't have an inherently benefic or malefic nature. Instead, it takes on the characteristics of the planets it associates with. Mercury is known as the prince in the planetary cabinet, symbolizing communication, intellect, speech, logic, and adaptability. It governs learning, education, writing, and all forms of expression. Mercury's influence is felt in various aspects of life, including business, trade, and technology.</p>

        <h2>Mercury in the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>1st House (Ascendant):</strong> When Mercury occupies the 1st house, it blesses the native with intelligence, wit, and a curious mind. These individuals are often drawn to intellectual pursuits, possess strong communication skills, and have a natural inclination towards business and trade. They are adaptable and quick learners, making them successful in various fields.</li>
          <li><strong>2nd House (Dhana Bhava):</strong> Mercury in the 2nd house signifies a way with words and a talent for communication. These individuals may excel in fields like writing, public speaking, or law. They are financially astute and may accumulate wealth through their communication skills and business acumen.</li>
          <li><strong>3rd House (Sahaja Bhava):</strong> This placement of Mercury amplifies communication skills and creative expression. These individuals may be talented writers, journalists, or artists. They have a strong bond with siblings and enjoy short travels and adventures.</li>
          <li><strong>4th House (Bandhu Bhava):</strong> Mercury in the 4th house indicates a deep thinker with a focus on home and family. These individuals may be interested in real estate or property-related matters. They value education and may have a scholarly inclination.</li>
          <li><strong>5th House (Putra Bhava):</strong> This placement of Mercury enhances creativity and intelligence. These individuals may excel in fields like writing, acting, or the arts. They have a love for learning and may be drawn to speculative businesses or investments.</li>
          <li><strong>6th House (Ari Bhava):</strong> Mercury in the 6th house indicates a sharp mind with a focus on problem-solving. These individuals may be drawn to the medical field, law, or any profession that requires analytical skills. They may face challenges in their work life but can overcome them through their intelligence and adaptability.</li>
          <li><strong>7th House (Yuvati Bhava):</strong> This placement of Mercury highlights communication and negotiation skills in partnerships and relationships. These individuals may be successful in business partnerships or legal matters. They are charming and diplomatic, making them good at building relationships.</li>
          <li><strong>8th House (Randhra Bhava):</strong> Mercury in the 8th house indicates an interest in occult sciences, research, and investigation. These individuals may be drawn to fields like astrology, psychology, or investigative journalism. They may face challenges in their personal life but can overcome them through their resilience and adaptability.</li>
          <li><strong>9th House (Dharma Bhava):</strong> This placement of Mercury signifies a love for learning, philosophy, and spirituality. These individuals may be drawn to higher education, teaching, or religious studies. They have a strong moral compass and may be involved in philanthropic activities.</li>
          <li><strong>10th House (Karma Bhava):</strong> Mercury in the 10th house indicates a successful career in communication, media, or business. These individuals are ambitious and hardworking, and they may achieve recognition and fame in their chosen field. They are good at managing people and may hold leadership positions.</li>
          <li><strong>11th House (Labha Bhava):</strong> This placement of Mercury brings financial gains and success in networking and social circles. These individuals may have many friends and connections, and they may accumulate wealth through their social skills and business acumen.</li>
          <li><strong>12th House (Vyaya Bhava):</strong> Mercury in the 12th house indicates an interest in spirituality, imagination, and foreign lands. These individuals may be drawn to creative fields like writing, filmmaking, or music. They may also have a strong intuition and psychic abilities.</li>
        </ul>

        <h2>Mercury in the Twelve Signs</h2>
        <ul className="enlarged-text">
          <li><strong>Aries:</strong> Mercury in Aries is energetic, impulsive, and assertive. They are quick thinkers and communicators but may also be prone to arguments and conflicts.</li>
          <li><strong>Taurus:</strong> Mercury in Taurus is practical, grounded, and methodical. They have a good memory and are skilled at managing finances and resources.</li>
          <li><strong>Gemini:</strong> Mercury in Gemini is witty, versatile, and adaptable. They are excellent communicators and may excel in fields like writing, journalism, or public speaking.</li>
          <li><strong>Cancer:</strong> Mercury in Cancer is intuitive, imaginative, and emotional. They are good at understanding others' feelings and may be drawn to creative or artistic pursuits.</li>
          <li><strong>Leo:</strong> Mercury in Leo is confident, expressive, and dramatic. They are natural leaders and may excel in fields like acting, politics, or public speaking.</li>
          <li><strong>Virgo:</strong> Mercury in Virgo is analytical, detail-oriented, and practical. They are good at problem-solving and may excel in fields like science, medicine, or engineering.</li>
          <li><strong>Libra:</strong> Mercury in Libra is diplomatic, charming, and sociable. They are good at building relationships and may excel in fields like law, public relations, or sales.</li>
          <li><strong>Scorpio:</strong> Mercury in Scorpio is intense, secretive, and investigative. They are good at research and analysis and may excel in fields like science, medicine, or detective work.</li>
          <li><strong>Sagittarius:</strong> Mercury in Sagittarius is philosophical, optimistic, and adventurous. They are good at learning and teaching and may excel in fields like education, law, or religion.</li>
          <li><strong>Capricorn:</strong> Mercury in Capricorn is ambitious, disciplined, and practical. They are good at business and management and may excel in fields like finance, accounting, or administration.</li>
          <li><strong>Aquarius:</strong> Mercury in Aquarius is innovative, independent, and unconventional. They are good at problem-solving and may excel in fields like science, technology, or social activism.</li>
          <li><strong>Pisces:</strong> Mercury in Pisces is intuitive, imaginative, and compassionate. They are good at understanding others' feelings and may be drawn to creative or artistic pursuits.</li>
        </ul>
      </section>
    </main>
  );
}

export default Mercury;
