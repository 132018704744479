import React, { useEffect } from 'react';
import '../App.css';

function Navamsa() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Understanding the Navamsa Chart (D9)</h1>

                <h2>Introduction to the Navamsa Chart (D9)</h2>

                <p>The Navamsa chart, also known as the D9 chart, holds significant importance in Vedic Astrology. It is considered as one of the most critical divisional (varga) charts. It reveals one's fortune, destiny, and relationships. Understanding the Navamsa chart enables deeper insights into a person's life and future prospects.</p>

                <p>In Vedic Astrology, varga charts are derived by dividing each zodiac sign into smaller proportional parts. The D9 chart, or the ninth division, symbolizes the culmination of one's efforts and rewards, often referred to as "fruits of dharma." The Navamsa chart reflects the consequences of one's righteous actions (dharmic deeds) from past lives, manifesting as fortunate circumstances in the present. This chart is closely associated with the ninth house of the birth chart, which represents dharmic actions and religiosity.</p>

                <p>The chart is also linked to marriage because the eleventh house from the ninth house (representing gain) is the seventh house of marriage. Contrary to a popular misconception, the Navamsa chart does not activate only at a certain age, like sometime in your 30s, nor does it depend solely on marriage. The Navamsa chart begins its influence from the moment of birth and operates in harmony with the D1 natal chart throughout one's life. This interplay is evident even in children's charts, where transits to the Navamsa reveal critical life events.</p>

                <h2>Important Techniques for Navamsa Interpretation</h2>

                <h3>1. Ninth Lord Predictions</h3>

                <p>The ninth lord of the birth chart is pivotal in interpreting the Navamsa chart. The placement of this lord in the D9 chart reveals the strength of one's dharma bhagya (fruits of dharma). For instance:</p>

                <ul className="enlarged-text">
                    <li>If the ninth lord is in its own sign, exalted, or in a friendly sign in the Navamsa, it indicates strong dharma.</li>
                    <li>If afflicted, it may still bring good karma but with some associated loss.</li>
                    <li>If debilitated, remedial measures may be necessary to strengthen the planet.</li>
                </ul>

                <h3>2. Predicting Changes in Fortune by Planet Maturity</h3>

                <p>Each planet matures at different ages. Their ages of maturity are listed below:</p>

                <ul className="enlarged-text">
                    <li>Sun -- 21 years</li>
                    <li>Moon -- 24 years</li>
                    <li>Mercury -- 32 years</li>
                    <li>Venus -- 25 years</li>
                    <li>Mars -- 28 years</li>
                    <li>Jupiter -- 16 years</li>
                    <li>Saturn -- 36 years</li>
                    <li>Rahu -- 42 years</li>
                    <li>Ketu -- 48 years</li>
                </ul>

                <p>To determine shifts in fortune, observe the ascendant lord of the D1 chart and its placement in the D9 chart. The maturity age of this planet often marks significant changes in life circumstances. Each planet matures at a specific age, influencing fortune and circumstances. For instance, Venus matures at 25, marking potential turning points for individuals with Taurus or Libra ascendants. The nature of the change (positive or negative) depends on the strength of the ascendant lord in the Navamsa. Similarly, the ninth house lord's maturity can indicate periods of positive transformation if it is well-placed in the D9 chart. Remedies can help navigate challenges if the planet is debilitated or afflicted in the D9 chart.</p>

                <h3>3. House Meanings in Navamsa</h3>

                <p>Unlike the D1 chart, houses in the Navamsa chart are interpreted differently, as they pertain to dharma and its manifestations:</p>

                <ul className="enlarged-text">
                    <li><strong>First House:</strong> Represents dharma and religiosity, not physical appearance.</li>
                    <li><strong>Second House:</strong> Indicates career.</li>
                    <li><strong>Third House:</strong> Gains, 11th house activities.</li>
                    <li><strong>Fourth House:</strong> 12th house matters such as losses, expenses, and bed pleasures.</li>
                    <li><strong>Fifth House:</strong> Self, health, vitality, personality.</li>
                    <li><strong>Sixth House:</strong> Savings, resources.</li>
                    <li><strong>Seventh House:</strong> Represents spouse and desires.</li>
                    <li><strong>Eighth House:</strong> Relates to home life.</li>
                    <li><strong>Ninth House:</strong> Indicates children (especially for females).</li>
                    <li><strong>Tenth House:</strong> Money, conflicts, and health issues.</li>
                    <li><strong>Eleventh House:</strong> Shows spouse as the gain.</li>
                    <li><strong>Twelfth House:</strong> Represents death.</li>
                </ul>

                <h3>4. D9 Ascendant in D1 Chart</h3>

                <p>This Jaimini technique involves locating the Navamsa D9 ascendant sign in the D1 birth chart. The second house from this position in the D1 birth chart becomes a focus in one's life and indicates what sustains the person. For example:</p>

                <ul className="enlarged-text">
                    <li>If D9 ascendant is the same as D1 ascendant: Focus on health, strong values</li>
                    <li>In 2nd house of D1: Focus on communication, courage</li>
                    <li>In 3rd house of D1: Focus on home, stability</li>
                    <li>In 4th house of D1: Focus on knowledge, children</li>
                    <li>In 5th house of D1: Focus on competition, health</li>
                    <li>In 6th house of D1: Focus on relationships, compromise</li>
                    <li>In 7th house of D1: Focus on transformation</li>
                    <li>In 8th house of D1: Focus on spirituality, philosophy</li>
                    <li>In 9th house of D1: Focus on career, status</li>
                    <li>In 10th house of D1: Focus on gains, popularity</li>
                    <li>In 11th house of D1: Focus on spirituality, liberation</li>
                    <li>In 12th house of D1: Focus on self, confidence</li>
                </ul>

                <h2>Planets in Trines in Navamsa</h2>

                <p>The placement of planets in trine houses (1st, 5th, and 9th) in the Navamsa chart is significant. These placements indicate prominent talents and ways of interacting with the world. Some examples include:</p>

                <ul className="enlarged-text">
                    <li>Sun: Musical talent, self-promotion</li>
                    <li>Moon: Unique voice, public impact</li>
                    <li>Mercury: Communication skills, business acumen</li>
                    <li>Venus: Artistic sense, fashion</li>
                    <li>Mars: Aggression, fighting spirit</li>
                    <li>Jupiter: Wisdom, ability to sustain others</li>
                    <li>Saturn: Traditionalism, orthodox views</li>
                    <li>Rahu: Talent with machinery, abstract thinking</li>
                    <li>Ketu: Spirituality, intuition, technology skills</li>
                </ul>

                <h2>Relationships in Navamsa</h2>

                <p>The Navamsa chart provides insights into one's committed relationships. Key points to consider:</p>

                <h3>1. Definition of spouse</h3>
                <p>A committed, public relationship, regardless of duration.</p>

                <h3>2. General indications</h3>
                <p>Planets in kendra houses (1, 4, 7, 10) are important. Venus is favorable, while Saturn can bring challenges.</p>

                <h3>3. Individual relationships</h3>
                <p>Each relationship is judged from a specific house in the Navamsa.</p>

                <ul className="enlarged-text">
                    <li>1st marriage: 7th house</li>
                    <li>2nd marriage: 2nd house (8 houses from 7th)</li>
                    <li>3rd marriage: 9th house (8 houses from 2nd)</li>
                    <li>4th marriage: 4th house (8 houses from 9th)</li>
                    <li>Subsequent marriages: Continue the pattern of 8 houses apart</li>
                </ul>

                <h3>4. Factors to consider for each relationship</h3>

                <ul className="enlarged-text">
                    <li>Sign of the relationship's ascendant</li>
                    <li>Planets in kendra from the relationship's ascendant</li>
                    <li>Placement of the ruling planet of the relationship's ascendant</li>
                    <li>Strength of the 2nd house from the relationship's ascendant</li>
                    <li>Position of the Moon (can indicate changefulness)</li>
                </ul>
            </section>
        </main>
    );
}

export default Navamsa;
