import React, { useEffect } from 'react';
import '../App.css';

function Nakshatras() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="nakshatras">
        <h1>Nakshatras Overview</h1>

        <p>
          In Vedic astrology, Nakshatras are lunar mansions or star constellations that play a crucial role in the astrological framework. There are 27 Nakshatras, each covering 13 degrees and 20 minutes of the zodiac. The moon travels through these Nakshatras and spends approximately one day in each. Nakshatras are significant because they provide deeper insights into the nature, behavior, and destiny of individuals based on the position of the moon at the time of their birth. Understanding Nakshatras can help in comprehending various life aspects such as personality traits, potential life events, and compatibility with others.
        </p>

        <h2>The 27 Nakshatras</h2>

        <h3>Ashwini (0° - 13° 20' Aries)</h3>
        <p>
          <strong>Symbol:</strong> Horse's Head<br/>
          <strong>Ruling Planet:</strong> Ketu<br/>
          <strong>Deity:</strong> Ashwini Kumaras (Healers of Gods)<br/>
          <strong>Characteristics:</strong> Energetic, pioneering, and adventurous. Individuals born under Ashwini are often swift in action and have a healing touch.
        </p>

        <h3>Bharani (13° 20' - 26° 40' Aries)</h3>
        <p>
          <strong>Symbol:</strong> Yoni (Female reproductive organ)<br/>
          <strong>Ruling Planet:</strong> Venus<br/>
          <strong>Deity:</strong> Yama (God of Death)<br/>
          <strong>Characteristics:</strong> Creative, nurturing, and responsible. They often face transformative life experiences and are known for their strong will.
        </p>

        <h3>Krittika (26° 40' Aries - 10° 00' Taurus)</h3>
        <p>
          <strong>Symbol:</strong> Razor<br/>
          <strong>Ruling Planet:</strong> Sun<br/>
          <strong>Deity:</strong> Agni (Fire God)<br/>
          <strong>Characteristics:</strong> Courageous, determined, and fiery. Individuals are often sharp in speech and action with a strong sense of justice.
        </p>

        <h3>Rohini (10° 00' - 23° 20' Taurus)</h3>
        <p>
          <strong>Symbol:</strong> Chariot<br/>
          <strong>Ruling Planet:</strong> Moon<br/>
          <strong>Deity:</strong> Brahma (Creator God)<br/>
          <strong>Characteristics:</strong> Attractive, charismatic, and artistic. They have a nurturing nature and often excel in creative fields.
        </p>

        <h3>Mrigashira (23° 20' Taurus - 6° 40' Gemini)</h3>
        <p>
          <strong>Symbol:</strong> Deer's Head<br/>
          <strong>Ruling Planet:</strong> Mars<br/>
          <strong>Deity:</strong> Soma (Moon God)<br/>
          <strong>Characteristics:</strong> Curious, adaptable, and sociable. Known for their quest for knowledge and love of travel.
        </p>

        <h3>Ardra (6° 40' - 20° 00' Gemini)</h3>
        <p>
          <strong>Symbol:</strong> Teardrop<br/>
          <strong>Ruling Planet:</strong> Rahu<br/>
          <strong>Deity:</strong> Rudra (Storm God)<br/>
          <strong>Characteristics:</strong> Intense, transformative, and intellectual. Individuals may face emotional upheavals but are resilient.
        </p>

        <h3>Punarvasu (20° 00' Gemini - 3° 20' Cancer)</h3>
        <p>
          <strong>Symbol:</strong> Bow and Quiver<br/>
          <strong>Ruling Planet:</strong> Jupiter<br/>
          <strong>Deity:</strong> Aditi (Mother of Gods)<br/>
          <strong>Characteristics:</strong> Optimistic, resourceful, and spiritual. They tend to bounce back from adversity.
        </p>

        <h3>Pushya (3° 20' - 16° 40' Cancer)</h3>
        <p>
          <strong>Symbol:</strong> Cow's Udder<br/>
          <strong>Ruling Planet:</strong> Saturn<br/>
          <strong>Deity:</strong> Brihaspati (Guru of Gods)<br/>
          <strong>Characteristics:</strong> Nourishing, caring, and disciplined. Known for their strong ethical values and dedication.
        </p>

        <h3>Ashlesha (16° 40' - 30° 00' Cancer)</h3>
        <p>
          <strong>Symbol:</strong> Coiled Serpent<br/>
          <strong>Ruling Planet:</strong> Mercury<br/>
          <strong>Deity:</strong> Nagas (Serpent Deities)<br/>
          <strong>Characteristics:</strong> Mysterious, intuitive, and perceptive. They possess a strong analytical mind and can be quite persuasive.
        </p>

        <h3>Magha (0° 00' - 13° 20' Leo)</h3>
        <p>
          <strong>Symbol:</strong> Royal Throne<br/>
          <strong>Ruling Planet:</strong> Ketu<br/>
          <strong>Deity:</strong> Pitris (Ancestors)<br/>
          <strong>Characteristics:</strong> Regal, authoritative, and ambitious. Individuals often have a strong connection to their heritage and traditions.
        </p>

        <h3>Purva Phalguni (13° 20' - 26° 40' Leo)</h3>
        <p>
          <strong>Symbol:</strong> Front Legs of a Bed<br/>
          <strong>Ruling Planet:</strong> Venus<br/>
          <strong>Deity:</strong> Bhaga (God of Fortune)<br/>
          <strong>Characteristics:</strong> Charming, luxurious, and pleasure-loving. They enjoy socializing and often possess artistic talents.
        </p>

        <h3>Uttara Phalguni (26° 40' Leo - 10° 00' Virgo)</h3>
        <p>
          <strong>Symbol:</strong> Back Legs of a Bed<br/>
          <strong>Ruling Planet:</strong> Sun<br/>
          <strong>Deity:</strong> Aryaman (God of Patronage)<br/>
          <strong>Characteristics:</strong> Generous, loyal, and reliable. Known for their commitment to relationships and partnerships.
        </p>

        <h3>Hasta (10° 00' - 23° 20' Virgo)</h3>
        <p>
          <strong>Symbol:</strong> Hand<br/>
          <strong>Ruling Planet:</strong> Moon<br/>
          <strong>Deity:</strong> Savitar (Sun God)<br/>
          <strong>Characteristics:</strong> Skillful, dexterous, and resourceful. They excel in craftsmanship and are often practical and methodical.
        </p>

        <h3>Chitra (23° 20' Virgo - 6° 40' Libra)</h3>
        <p>
          <strong>Symbol:</strong> Bright Jewel<br/>
          <strong>Ruling Planet:</strong> Mars<br/>
          <strong>Deity:</strong> Tvashtar (Divine Architect)<br/>
          <strong>Characteristics:</strong> Creative, dynamic, and aesthetically inclined. Individuals have a keen eye for beauty and design.
        </p>

        <h3>Swati (6° 40' - 20° 00' Libra)</h3>
        <p>
          <strong>Symbol:</strong> Coral<br/>
          <strong>Ruling Planet:</strong> Rahu<br/>
          <strong>Deity:</strong> Vayu (Wind God)<br/>
          <strong>Characteristics:</strong> Independent, flexible, and adaptable. Known for their communicative abilities and desire for freedom.
        </p>

        <h3>Vishakha (20° 00' Libra - 3° 20' Scorpio)</h3>
        <p>
          <strong>Symbol:</strong> Triumphal Arch<br/>
          <strong>Ruling Planet:</strong> Jupiter<br/>
          <strong>Deity:</strong> Indra and Agni (Gods of Transformation)<br/>
          <strong>Characteristics:</strong> Determined, ambitious, and goal-oriented. They possess a strong drive to succeed and often undergo significant transformations.
        </p>

        <h3>Anuradha (3° 20' - 16° 40' Scorpio)</h3>
        <p>
          <strong>Symbol:</strong> Lotus<br/>
          <strong>Ruling Planet:</strong> Saturn<br/>
          <strong>Deity:</strong> Mitra (God of Friendship)<br/>
          <strong>Characteristics:</strong> Compassionate, loyal, and supportive. They are known for their ability to maintain harmonious relationships.
       
        </p>

        <h3>Jyeshta (16° 40' - 30° 00' Scorpio)</h3>
        <p>
        <strong>Symbol:</strong> Elder Star<br/>
        <strong>Ruling Planet:</strong> Mercury<br/>
        <strong>Deity:</strong> Indra (King of Gods)<br/>
        <strong>Characteristics:</strong> Responsible, protective, and influential. They often hold positions of authority and are respected for their wisdom.
        </p>

        <h3>Mula (0° 00' - 13° 20' Sagittarius)</h3>
        <p>
        <strong>Symbol:</strong> Tied Bundle of Roots<br/>
        <strong>Ruling Planet:</strong> Ketu<br/>
        <strong>Deity:</strong> Nirriti (Goddess of Destruction)<br/>
        <strong>Characteristics:</strong> Deep, philosophical, and introspective. Individuals seek to uncover the roots of existence and are often drawn to spiritual pursuits.
        </p>

        <h3>Purva Ashadha (13° 20' - 26° 40' Sagittarius)</h3>
        <p>
        <strong>Symbol:</strong> Elephant's Tusk<br/>
        <strong>Ruling Planet:</strong> Venus<br/>
        <strong>Deity:</strong> Apah (Water Goddess)<br/>
        <strong>Characteristics:</strong> Invincible, ambitious, and enthusiastic. Known for their determination and ability to overcome obstacles.
        </p>

        <h3>Uttara Ashadha (26° 40' Sagittarius - 10° 00' Capricorn)</h3>
        <p>
        <strong>Symbol:</strong> Planks of a Bed<br/>
        <strong>Ruling Planet:</strong> Sun<br/>
        <strong>Deity:</strong> Vishvadevas (Universal Gods)<br/>
        <strong>Characteristics:</strong> Responsible, ethical, and focused. They strive for long-term success and are often seen as natural leaders.
        </p>

        <h3>Shravana (10° 00' - 23° 20' Capricorn)</h3>
        <p>
        <strong>Symbol:</strong> Ear<br/>
        <strong>Ruling Planet:</strong> Moon<br/>
        <strong>Deity:</strong> Vishnu (Preserver of the Universe)<br/>
        <strong>Characteristics:</strong> Attentive, wise, and knowledgeable. They are excellent listeners and often possess a deep understanding of various subjects.
        </p>

        <h3>Dhanishta (23° 20' Capricorn - 6° 40' Aquarius)</h3>
        <p>
        <strong>Symbol:</strong> Drum<br/>
        <strong>Ruling Planet:</strong> Mars<br/>
        <strong>Deity:</strong> Eight Vasus (Elements of Earth)<br/>
        <strong>Characteristics:</strong> Prosperous, musical, and rhythmic. Individuals have a strong sense of timing and often excel in the arts and performance.
        </p>

        <h3>Shatabhisha (6° 40' - 20° 00' Aquarius)</h3>
        <p>
        <strong>Symbol:</strong> Empty Circle<br/>
        <strong>Ruling Planet:</strong> Rahu<br/>
        <strong>Deity:</strong> Varuna (God of Cosmic Waters)<br/>
        <strong>Characteristics:</strong> Healing, secretive, and mysterious. Known for their ability to uncover hidden truths and provide healing.
        </p>

        <h3>Purva Bhadrapada (20° 00' Aquarius - 3° 20' Pisces)</h3>
        <p>
        <strong>Symbol:</strong> Sword<br/>
        <strong>Ruling Planet:</strong> Jupiter<br/>
        <strong>Deity:</strong> Aja Ekapada (One-Footed Goat)<br/>
        <strong>Characteristics:</strong> Intense, passionate, and transformative. They often experience profound changes and are driven by deep convictions.
        </p>

        <h3>Uttara Bhadrapada (3° 20' - 16° 40' Pisces)</h3>
        <p>
        <strong>Symbol:</strong> Twin Fish<br/>
        <strong>Ruling Planet:</strong> Saturn<br/>
        <strong>Deity:</strong> Ahir Budhnya (Serpent of the Deep)<br/>
        <strong>Characteristics:</strong> Deep, introspective, and wise. Individuals are often spiritually inclined and possess a strong sense of empathy and compassion.
        </p>

        <h3>Revati (16° 40' - 30° 00' Pisces)</h3>
        <p>
        <strong>Symbol:</strong> Fish<br/>
        <strong>Ruling Planet:</strong> Mercury<br/>
        <strong>Deity:</strong> Pushan (Protector of Herds)<br/>
        <strong>Characteristics:</strong> Nurturing, gentle, and protective. They have a caring nature and often excel in roles that require looking after others.
        </p>
        </section>

        <section id="detailed-description">
        <h2>Detailed Description of Each Nakshatra</h2>
        
        <h3>Ashwini Nakshatra</h3>
        <p>
        <strong>Overview:</strong> The Ashwini Nakshatra is the first among the 27 Nakshatras and is associated with speed, agility, and initiation. It is symbolized by a horse's head representing swiftness and power.<br/>
        <strong>Personality Traits:</strong> People born under Ashwini are often energetic, enthusiastic, and spontaneous. They have a strong desire to initiate new projects and can be pioneers in their fields.<br/>
        <strong>Positive Qualities:</strong> Adventurous, innovative, healing abilities.<br/>
        <strong>Challenges:</strong> Impulsiveness, lack of patience.<br/>
        <strong>Professions:</strong> Medicine, sports, military, and any field requiring quick decision-making.
        </p>

        <h3>Bharani Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Bharani Nakshatra, symbolized by the Yoni, represents creativity and the cycle of birth and death. It is associated with Venus, which bestows a nurturing and artistic nature.<br/>
        <strong>Personality Traits:</strong> Individuals are usually creative, responsible, and possess strong willpower. They often undergo transformative experiences.<br/>
        <strong>Positive Qualities:</strong> Creative, nurturing, strong-willed.<br/>
        <strong>Challenges:</strong> Stubbornness, facing intense life changes.<br/>
        <strong>Professions:</strong> Arts, caregiving, counseling, any field involving creativity and responsibility.
        </p>

        <h3>Krittika Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Krittika, symbolized by a razor, represents sharpness and cutting through obstacles. Governed by the Sun, it bestows determination and courage.<br/>
        <strong>Personality Traits:</strong> Individuals are brave, determined, and have a keen sense of justice. They are often seen as leaders.<br/>
        <strong>Positive Qualities:</strong> Courageous, determined, sharp-minded.<br/>
        <strong>Challenges:</strong> Harshness, tendency to be overly critical.<br/>
        <strong>Professions:</strong> Leadership roles, military, law enforcement, surgeons.
        </p>

        <h3>Rohini Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Rohini, represented by a chariot, is associated with beauty, charm, and creativity. Governed by the Moon, it imparts a nurturing and attractive personality.<br/>
        <strong>Personality Traits:</strong> Charismatic, attractive, and artistic individuals born under Rohini have a natural talent for creative endeavors.<br/>
        <strong>Positive Qualities:</strong> Attractive, charismatic, nurturing.<br/>
        <strong>Challenges:</strong> Possessiveness, materialistic tendencies.<br/>
        <strong>Professions:</strong> Arts, entertainment, fashion, real estate.
        </p>

        <h3>Mrigashira Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a deer’s head, Mrigashira represents curiosity and the search for knowledge. Mars as the ruling planet provides energy and adaptability.<br/>
        <strong>Personality Traits:</strong> Curious, sociable, and adaptable; they are always in search of new knowledge and experiences.<br/>
        <strong>Positive Qualities:</strong> Adaptable, curious, sociable.<br/>
        <strong>Challenges:</strong> Restlessness, tendency to overthink.<br/>
        <strong>Professions:</strong> Travel, education, sales, journalism.
        </p>

        <h3>Ardra Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by a teardrop, Ardra is associated with storms and transformation. Rahu, the ruling planet, imparts intensity and intellectual depth.<br/>
        <strong>Personality Traits:</strong> Intense, transformative, and intellectual individuals often face emotional upheavals but are resilient.<br/>
        <strong>Positive Qualities:</strong> Intense, resilient, intellectual.<br/>
        <strong>Challenges:</strong> Emotional turbulence, impulsive decisions.<br/>
        <strong>Professions:</strong> Psychology, research, technology, any field requiring deep analysis.
        </p>

        <h3>Punarvasu Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a bow and quiver, Punarvasu represents renewal and optimism. Governed by Jupiter, it bestows wisdom and resourcefulness.<br/>
        <strong>Personality Traits:</strong> Optimistic, resourceful, and spiritual individuals have the ability to bounce back from adversity.<br/>
        <strong>Positive Qualities:</strong> Optimistic, resourceful, spiritual.<br/>
        <strong>Challenges:</strong> Over-idealism, difficulty in staying grounded.<br/>
        <strong>Professions:</strong> Teaching, counseling, writing, any field involving renewal and wisdom.
        </p>

        <h3>Pushya Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by a cow's udder, Pushya symbolizes nourishment and care. Saturn as the ruling planet bestows discipline and ethical values.<br/>
        <strong>Personality Traits:</strong> Nourishing, disciplined, and ethical individuals are dedicated and have strong values.<br/>
        <strong>Positive Qualities:</strong> Caring, disciplined, ethical.<br/>
        <strong>Challenges:</strong> Rigidity, over-dedication to work.<br/>
        <strong>Professions:</strong> Nursing, teaching, agriculture, law.
        </p>

        <h3>Ashlesha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a coiled serpent, Ashlesha represents mystery and intuition. Governed by Mercury, it imparts analytical and persuasive abilities.<br/>
        <strong>Personality Traits:</strong> Mysterious, intuitive, and perceptive individuals have a strong analytical mind and can be persuasive.<br/>
        <strong>Positive Qualities:</strong> Intuitive, perceptive, analytical.<br/>
        <strong>Challenges:</strong> Secretive, manipulative tendencies.<br/>
        <strong>Professions:</strong> Research, psychology, espionage, any field requiring deep analysis.
        </p>

        <h3>Magha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by a royal throne, Magha symbolizes authority and ambition. Ketu as the ruling planet imparts a strong connection to heritage and traditions.<br/>
        <strong>Personality Traits:</strong> Regal, authoritative, and ambitious individuals often have a strong connection to their ancestry.<br/>
        <strong>Positive Qualities:</strong> Ambitious, authoritative, traditional.<br/>
        <strong>Challenges:</strong> Arrogance, resistance to change.<br/>
        <strong>Professions:</strong> Politics, leadership roles, heritage conservation, any field involving authority.
        </p>

        <h3>Purva Phalguni Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by the front legs of a bed, Purva Phalguni represents comfort and luxury. Governed by Venus, it bestows charm and a love for pleasure.<br/>
        <strong>Personality Traits:</strong> Charming, luxurious, and pleasure-loving individuals enjoy socializing and often have artistic talents.<br/>
        <strong>Positive Qualities:</strong> Charming, artistic, pleasure-loving.<br/>
        <strong>Challenges:</strong> Laziness, indulgence.<br/>
        <strong>Professions:</strong> Arts, entertainment, hospitality, any field involving luxury and comfort.
        </p>

        <h3>Uttara Phalguni Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by the back legs of a bed, Uttara Phalguni symbolizes loyalty and generosity. The Sun as the ruling planet imparts reliability and commitment.<br/>
        <strong>Personality Traits:</strong> Generous, loyal, and reliable individuals are known for their commitment to relationships.<br/>
        <strong>Positive Qualities:</strong> Loyal, generous, reliable.<br/>
        <strong>Challenges:</strong> Stubbornness, difficulty in adapting.<br/>
        <strong>Professions:</strong> Counseling, partnership roles, social work, any field requiring loyalty and commitment.
        </p>

        <h3>Hasta Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a hand, Hasta represents skill and dexterity. Governed by the Moon, it imparts resourcefulness and practicality.<br/>
        <strong>Personality Traits:</strong> Skillful, dexterous, and resourceful individuals excel in craftsmanship and are practical and methodical.<br/>
        <strong>Positive Qualities:</strong> Skillful, resourceful, practical.<br/>
        <strong>Challenges:</strong> Perfectionism, restlessness.<br/>
        <strong>Professions:</strong> Craftsmanship, healthcare, teaching, any field requiring manual dexterity and skill.
        </p>

        <h3>Chitra Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by a bright jewel, Chitra symbolizes beauty and design. Mars as the ruling planet bestows creativity and dynamism.<br/>
        <strong>Personality Traits:</strong> Creative, dynamic, and aesthetically inclined individuals have a keen eye for beauty and design.<br/>
        <strong>Positive Qualities:</strong> Creative, dynamic, aesthetic.<br/>
        <strong>Challenges:</strong> Vanity, impulsiveness.<br/>
        <strong>Professions:</strong> Design, architecture, arts, any field involving creativity and aesthetics.
        </p>

        <h3>Swati Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by coral, Swati represents independence and flexibility. Rahu, the ruling planet, imparts communicative abilities and a desire for freedom.<br/>
        <strong>Personality Traits:</strong> Independent, flexible, and adaptable individuals are known for their communicative abilities.<br/>
        <strong>Positive Qualities:</strong> Independent, communicative, adaptable.<br/>
        <strong>Challenges:</strong> Inconsistency, detachment.<br/>
        <strong>Professions:</strong> Media, public relations, marketing, any field requiring communication and adaptability.
        </p>

        <h3>Vishakha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by a triumphal arch, Vishakha symbolizes determination and ambition. Governed by Jupiter, it bestows goal-oriented and transformative qualities.<br/>
        <strong>Personality Traits:</strong> Determined, ambitious, and goal-oriented individuals possess a strong drive to succeed.<br/>
        <strong>Positive Qualities:</strong> Ambitious, transformative, determined.<br/>
        <strong>Challenges:</strong> Over-ambition, impatience.<br/>
        <strong>Professions:</strong> Business, politics, research, any field requiring ambition and determination.
        </p>

        <h3>Anuradha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a lotus, Anuradha represents compassion and loyalty. Saturn as the ruling planet imparts supportive and harmonious qualities.<br/>
        <strong>Personality Traits:</strong> Compassionate, loyal, and supportive individuals are known for maintaining harmonious relationships.<br/>
        <strong>Positive Qualities:</strong> Loyal, supportive, harmonious.<br/>
        <strong>Challenges:</strong> Over-sensitivity, attachment.<br/>
        <strong>Professions:</strong> Counseling, social work, healthcare, any field requiring compassion and loyalty.
        </p>

        <h3>Jyeshta Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by an elder star, Jyeshta symbolizes responsibility and influence. Governed by Mercury, it bestows protective and authoritative qualities.<br/>
        <strong>Personality Traits:</strong> Responsible, protective, and influential individuals often hold positions of authority.<br/>
        <strong>Positive Qualities:</strong> Responsible, authoritative, influential.<br/>
        <strong>Challenges:</strong> Dominance, stress.<br/>
        <strong>Professions:</strong> Leadership roles, law, management, any field requiring responsibility and authority.
        </p>

        <h3>Mula Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a tied bundle of roots, Mula represents depth and transformation. Ketu as the ruling planet imparts philosophical and introspective qualities.<br/>
        <strong>Personality Traits:</strong> Deep, philosophical, and introspective individuals seek to uncover the roots of existence.<br/>
        <strong>Positive Qualities:</strong> Philosophical, deep, transformative.<br/>
        <strong>Challenges:</strong> Restlessness, detachment.<br/>
        <strong>Professions:</strong> Research, spirituality, psychology, any field requiring deep analysis and transformation.
        </p>

        <h3>Purva Ashadha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by an elephant's tusk, Purva Ashadha symbolizes invincibility and ambition. Governed by Venus, it bestows enthusiasm and determination.<br/>
        <strong>Personality Traits:</strong> Ambitious, enthusiastic, and determined individuals are known for their ability to overcome obstacles.<br/>
        <strong>Positive Qualities:</strong> Determined, enthusiastic, ambitious.<br/>
        <strong>Challenges:</strong> Stubbornness, overconfidence.<br/>
        <strong>Professions:</strong> Politics, business, sports, any field requiring ambition and determination.
        </p>

        <h3>Uttara Ashadha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by planks of a bed, Uttara Ashadha represents responsibility and focus. The Sun as the ruling planet imparts ethical and leadership qualities.<br/>
        <strong>Personality Traits:</strong> Responsible, ethical, and focused individuals strive for long-term success.<br/>
        <strong>Positive Qualities:</strong> Responsible, ethical, focused.<br/>
        <strong>Challenges:</strong> Rigidity, workaholism.<br/>
        <strong>Professions:</strong> Leadership roles, law, administration, any field requiring responsibility and focus.
        </p>

        <h3>Shravana Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by an ear, Shravana symbolizes listening and knowledge. Governed by the Moon, it bestows attentiveness and wisdom.<br/>
        <strong>Personality Traits:</strong> Attentive, wise, and knowledgeable individuals are excellent listeners and possess a deep understanding.<br/>
        <strong>Positive Qualities:</strong> Attentive, wise, knowledgeable.<br/>
        <strong>Challenges:</strong> Over-sensitivity, indecisiveness.<br/>
        <strong>Professions:</strong> Teaching, counseling, writing, any field requiring attentiveness and knowledge.
        </p>

        <h3>Dhanishta Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a drum, Dhanishta represents prosperity and rhythm. Mars as the ruling planet imparts musical and rhythmic abilities.<br/>
        <strong>Personality Traits:</strong> Prosperous, musical, and rhythmic individuals have a strong sense of timing.<br/>
        <strong>Positive Qualities:</strong> Musical, rhythmic, prosperous.<br/>
        <strong>Challenges:</strong> Over-competitiveness, stubbornness.<br/>
        <strong>Professions:</strong> Music, performance, arts, finance, any field requiring rhythm and prosperity.
        </p>

        <h3>Shatabhisha Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by an empty circle, Shatabhisha symbolizes healing and mystery. Governed by Rahu, it bestows secretive and healing abilities.<br/>
        <strong>Personality Traits:</strong> Healing, secretive, and mysterious individuals are known for uncovering hidden truths.<br/>
        <strong>Positive Qualities:</strong> Healing, secretive, mysterious.<br/>
        <strong>Challenges:</strong> Isolation, emotional turbulence.<br/>
        <strong>Professions:</strong> Healing arts, research, detective work, any field requiring uncovering secrets.
        </p>

        <h3>Purva Bhadrapada Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a sword, Purva Bhadrapada represents intensity and transformation. Jupiter as the ruling planet imparts passionate and transformative qualities.<br/>
        <strong>Personality Traits:</strong> Intense, passionate, and transformative individuals experience profound changes.<br/>
        <strong>Positive Qualities:</strong> Intense, transformative, passionate.<br/>
        <strong>Challenges:</strong> Extremism, emotional instability.<br/>
        <strong>Professions:</strong> Research, spirituality, counseling, any field requiring transformation and intensity.
        </p>

        <h3>Uttara Bhadrapada Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Represented by twin fish, Uttara Bhadrapada symbolizes depth and wisdom. Governed by Saturn, it bestows introspection and empathy.<br/>
        <strong>Personality Traits:</strong> Deep, introspective, and wise individuals are spiritually inclined and empathetic.<br/>
        <strong>Positive Qualities:</strong> Introspective, wise, empathetic.<br/>
        <strong>Challenges:</strong> Over-sensitivity, withdrawal.<br/>
        <strong>Professions:</strong> Spirituality, counseling, research, any field requiring depth and wisdom.
        </p>

        <h3>Revati Nakshatra</h3>
        <p>
        <strong>Overview:</strong> Symbolized by a fish, Revati represents nurturing and protection. Mercury as the ruling planet imparts caring and protective qualities.<br/>
        <strong>Personality Traits:</strong> Nurturing, gentle, and protective individuals excel in caring roles.<br/>
        <strong>Positive Qualities:</strong> Nurturing, protective, gentle.<br/>
        <strong>Challenges:</strong> Overprotectiveness, vulnerability.<br/>
        <strong>Professions:</strong> Healthcare, teaching, caregiving, any field requiring nurturing and protection.
        </p>
        </section>
        </main>
    );
}

export default Nakshatras;
