import React, { useEffect } from 'react';
import '../App.css';

function Planets() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Vedic Astrology: Understanding the Planets</h1>

                <p>In Vedic astrology, the planets play a crucial role in determining one's karmas and destiny. Unlike modern Western astrology, Vedic astrology focuses on nine celestial bodies, known as grahas. These grahas are believed to carry our karmas and influence our lives on Earth.</p>

                <p>The term "graha" means "to grasp" or "to hold," implying that these celestial bodies metaphysically grasp and pull us in certain directions. It's important to note that this is not a physical or scientifically measurable phenomenon, but rather a metaphysical concept.</p>

                <p>According to sage Parashara, a revered figure in Vedic astrology, Vishnu (God) takes the form of these nine grahas to give living beings (Jivas) the results of their karmas. This concept highlights two important aspects of Vedic astrology:</p>

                <ol className="enlarged-text">
                    <li>It connects us to the divine.</li>
                    <li>It shows that our birth chart represents the results of our past life actions (karmas).</li>
                </ol>

                <h2>The Nine Grahas in Vedic Astrology</h2>

                <p>Vedic astrology uses seven visible grahas and two shadow grahas:</p>

                <ol className="enlarged-text">
                    <li>Sun (Surya)</li>
                    <li>Moon (Chandra)</li>
                    <li>Mercury (Buddha)</li>
                    <li>Venus (Shukra)</li>
                    <li>Mars (Mangala)</li>
                    <li>Jupiter (Guru)</li>
                    <li>Saturn (Shani)</li>
                    <li>Rahu (North Node of the Moon)</li>
                    <li>Ketu (South Node of the Moon)</li>
                </ol>

                <p>The Vedic names of the grahas are considered divine and carry high vibrations, making them beneficial to use in astrological practice.</p>

                <h2>Key Concepts in Understanding Planets</h2>

                <h3>1. Benefic vs. Malefic Planets</h3>
                <p>Planets are categorized as either benefic (bringing helpful, good things) or malefic (bringing challenging, difficult circumstances). For example, Venus is benefic, while Saturn is malefic. However, their effects can vary depending on specific chart configurations.</p>

                <h3>2. Home Sign vs. Office Sign</h3>
                <p>Each planet rules certain signs, but they have a "home" sign where they feel most comfortable and an "office" or Moolatrikona sign where they work most effectively. For instance, Mars rules both Aries and Scorpio, but Scorpio is his home, while Aries is his office.</p>

                <h3>3. Gunas (Modes of Nature)</h3>
                <p>Planets are associated with different gunas or modes of nature:</p>
                <ul className="enlarged-text">
                    <li>Sattva: Purity, contentment</li>
                    <li>Rajas: Passion, desire, activity</li>
                    <li>Tamas: Ignorance, inertia, darkness</li>
                </ul>

                <h3>4. Karaka (Significator)</h3>
                <p>Each planet is a karaka or significator for certain aspects of life. This concept helps in understanding what areas of life a planet influences.</p>

                <h3>5. Elements (Tattvas)</h3>
                <p>Planets are associated with one of five elements: Earth (Prithvi), Fire (Agni), Water (Apas), Air (Vayu), and Aether (Akasha).</p>

                <h2>Understanding Individual Planets</h2>

                <h3>1. Sun (Surya)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Malefic</li>
                    <li>Home/Office Sign: Leo</li>
                    <li>Element: Fire</li>
                    <li>Energy: Male</li>
                    <li>Guna: Sattvic</li>
                    <li>Karaka: Soul</li>
                </ul>
                <p>The Sun represents our soul, spiritual self, and purpose. It is associated with authority, government, father (biological), dharma (righteous action), power, wealth, creativity, and success.</p>

                <h3>2. Moon (Chandra)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Benefic (most benefic when going towards full moon, not new moon)</li>
                    <li>Home Sign: Cancer</li>
                    <li>Office Sign: Taurus</li>
                    <li>Element: Water</li>
                    <li>Energy: Female (male deity)</li>
                    <li>Guna: Sattvic</li>
                    <li>Karaka: Mind</li>
                </ul>
                <p>The Moon signifies the mind, mother (biological), nurture, emotions, home, domesticity, food, longevity, family, and society.</p>

                <h3>3. Mercury (Buddha)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Benefic (can become malefic when with malefic planets)</li>
                    <li>Home Sign: Gemini</li>
                    <li>Office Sign: Virgo</li>
                    <li>Element: Earth</li>
                    <li>Energy: Neutral (gender neutral)</li>
                    <li>Guna: Rajasic</li>
                    <li>Karaka: Speech</li>
                </ul>
                <p>Mercury rules communication, intelligence, business, money, travel, friendship, youthfulness, and nature.</p>

                <h3>4. Venus (Shukra)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Benefic</li>
                    <li>Home Sign: Taurus</li>
                    <li>Office Sign: Libra</li>
                    <li>Element: Water</li>
                    <li>Energy: Female (male deity)</li>
                    <li>Guna: Rajasic</li>
                    <li>Karaka: Ojas (vitality)</li>
                </ul>
                <p>Venus governs love, beauty, sexuality, procreation, wife (in male charts), wealth, luxury, and artistic talents.</p>

                <h3>5. Mars (Mangala)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Malefic</li>
                    <li>Home Sign: Scorpio</li>
                    <li>Office Sign: Aries</li>
                    <li>Element: Fire</li>
                    <li>Energy: Male</li>
                    <li>Guna: Tamasic</li>
                    <li>Karaka: Strength</li>
                </ul>
                <p>Mars represents war, fighting, anger, courage, discipline, accidents, celibacy, and brothers.</p>

                <h3>6. Jupiter (Guru)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Benefic</li>
                    <li>Home Sign: Pisces</li>
                    <li>Office Sign: Sagittarius</li>
                    <li>Element: Aether</li>
                    <li>Energy: Male</li>
                    <li>Guna: Sattvic</li>
                    <li>Karaka: Bliss (of knowledge)</li>
                </ul>
                <p>Jupiter signifies wisdom, spirituality, education, children, abundance, peace, and ethics. In female charts, it represents the husband.</p>

                <h3>7. Saturn (Shani)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Malefic</li>
                    <li>Home Sign: Capricorn</li>
                    <li>Office Sign: Aquarius</li>
                    <li>Element: Air</li>
                    <li>Energy: Male</li>
                    <li>Guna: Tamasic</li>
                    <li>Karaka: Sorrow</li>
                </ul>
                <p>Saturn brings karmic challenges, delays, limitations, and structure. It is associated with longevity, depression, and fear, but also with patience and spiritual growth.</p>

                <h3>8. Rahu (North Node)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Malefic</li>
                    <li>Home Sign: Aquarius</li>
                    <li>Office Sign: Virgo</li>
                    <li>Element: Air</li>
                    <li>Energy: Male</li>
                    <li>Guna: Tamasic</li>
                    <li>Karaka: Illusion</li>
                </ul>
                <p>Rahu represents desire, illusion, foreign influences, unconventional behavior, and material ambition.</p>

                <h3>9. Ketu (South Node)</h3>
                <ul className="enlarged-text">
                    <li>Nature: Malefic</li>
                    <li>Home Sign: Scorpio</li>
                    <li>Office Sign: Pisces</li>
                    <li>Element: Fire</li>
                    <li>Energy: Male</li>
                    <li>Guna: Tamasic</li>
                    <li>Karaka: Moksha (liberation)</li>
                </ul>
                <p>Ketu signifies spirituality, detachment, past life connections, and renunciation. It can bring material struggles but spiritual growth.</p>
            </section>
        </main>
    );
}

export default Planets;
