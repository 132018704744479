import React, { useEffect } from 'react';
import '../App.css';

function Sun() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="sun">
        <h1>Introduction to the Sun in Vedic Astrology</h1>
        
        <p>
          The Sun is the most important planet in Vedic astrology. It represents the soul, the self, and the ego. It is the king of the planets, and its placement in a birth chart can tell us a lot about a person's personality, health, career, and relationships. The Sun is a fiery planet, and its energy is associated with warmth, light, and vitality. It is also associated with creativity, leadership, and power. When the Sun is strong in a birth chart, it can indicate a person who is confident, ambitious, and successful. However, when the Sun is weak, it can indicate a person who is insecure, timid, and lacking in direction.
        </p>
        <p>
          The Sun's placement in a particular house of the birth chart can tell us a lot about how the Sun's energy will manifest in that area of life. For example, the Sun in the 10th house is a very strong placement, as it is the house of career and public recognition. This placement can indicate a person who is successful in their career and who is well-respected by others. However, the Sun in the 12th house is a weaker placement, as it is the house of loss and isolation. This placement can indicate a person who struggles in their career or who is not well-known by others.
        </p>
        <p>
          The Sun's placement in a particular sign of the zodiac can also tell us a lot about how the Sun's energy will manifest. For example, the Sun in Aries is a very fiery and energetic placement, while the Sun in Libra is a more balanced and harmonious placement.
        </p>

        <h2>The Sun in the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>Sun in the First House:</strong> When the Sun is in the first house, the native is typically confident, independent, and has a strong sense of self. They are natural leaders and enjoy being in the spotlight. They are also likely to be physically fit and healthy. However, they can also be egoistic, arrogant, and domineering.</li>
          <li><strong>Sun in the Second House:</strong> The second house is the house of family, wealth, and speech. When the Sun is in this house, the native is likely to be financially successful and have a good relationship with their family. They may also be skilled in communication and public speaking. However, they can also be materialistic, greedy, and possessive.</li>
          <li><strong>Sun in the Third House:</strong> The third house is the house of siblings, communication, and short travels. When the Sun is in this house, the native is likely to be good at communication and have a strong relationship with their siblings. They may also enjoy traveling and exploring new places. However, they can also be restless, impulsive, and prone to accidents.</li>
          <li><strong>Sun in the Fourth House:</strong> The fourth house is the house of home, mother, and emotional security. When the Sun is in this house, the native is likely to be close to their mother and have a strong sense of home and family. They may also be interested in real estate or home improvement. However, they can also be moody, sensitive, and attached to the past.</li>
          <li><strong>Sun in the Fifth House:</strong> The fifth house is the house of children, creativity, and romance. When the Sun is in this house, the native is likely to be creative, romantic, and have a strong desire for children. They may also be interested in speculation or gambling. However, they can also be dramatic, egoistic, and prone to taking risks.</li>
          <li><strong>Sun in the Sixth House:</strong> The sixth house is the house of health, service, and daily routine. When the Sun is in this house, the native is likely to be health-conscious and have a strong work ethic. They may also be interested in helping others or working in a service-oriented profession. However, they can also be critical, perfectionistic, and prone to worry.</li>
          <li><strong>Sun in the Seventh House:</strong> The seventh house is the house of marriage, partnerships, and relationships. When the Sun is in this house, the native is likely to be focused on their relationships and may be married or in a committed partnership. They may also be interested in business or law. However, they can also be dependent on others, indecisive, and prone to conflict.</li>
          <li><strong>Sun in the Eighth House:</strong> The eighth house is the house of transformation, death, and rebirth. When the Sun is in this house, the native is likely to be interested in the occult, mystery, and the afterlife. They may also be interested in psychology or healing. However, they can also be secretive, manipulative, and prone to depression.</li>
          <li><strong>Sun in the Ninth House:</strong> The ninth house is the house of higher education, spirituality, and long-distance travel. When the Sun is in this house, the native is likely to be interested in philosophy, religion, and travel. They may also be interested in teaching or writing. However, they can also be dogmatic, judgmental, and self-righteous.</li>
          <li><strong>Sun in the Tenth House:</strong> The tenth house is the house of career, reputation, and public recognition. When the Sun is in this house, the native is likely to be successful in their career and well-respected by others. They may also be interested in politics or government. However, they can also be workaholics, power-hungry, and controlling.</li>
          <li><strong>Sun in the Eleventh House:</strong> The eleventh house is the house of gains, friends, and wishes. When the Sun is in this house, the native is likely to be popular, social, and have many friends. They may also be financially successful and have their wishes fulfilled. However, they can also be superficial, detached, and rebellious.</li>
          <li><strong>Sun in the Twelfth House:</strong> The twelfth house is the house of loss, isolation, and spirituality. When the Sun is in this house, the native is likely to be introspective, spiritual, and interested in helping others. They may also be interested in foreign travel or living abroad. However, they can also be lonely, isolated, and prone to addiction.</li>
        </ul>

        <h2>The Sun in the Twelve Signs</h2>
        <ul className="enlarged-text">
          <li><strong>Sun in Aries:</strong> The Sun is exalted in Aries, which means it is in its most powerful position. This gives the native a strong sense of self, courage, and leadership qualities. They are often driven and ambitious, with a desire to achieve great things. However, they can also be impulsive, impatient, and quick to anger. They may also suffer from health problems related to the head or eyes.</li>
          <li><strong>Sun in Taurus:</strong> The Sun in Taurus indicates a person who is patient, reliable, and practical. They are often down-to-earth and enjoy the simple things in life. They are also likely to be financially successful and have a strong work ethic. However, they can also be stubborn, possessive, and resistant to change. They may also suffer from health problems related to the throat or neck.</li>
          <li><strong>Sun in Gemini:</strong> The Sun in Gemini indicates a person who is intelligent, curious, and communicative. They are often quick-witted and enjoy learning new things. They are also likely to be social and have a wide circle of friends. However, they can also be scattered, indecisive, and prone to gossip. They may also suffer from health problems related to the nervous system or lungs.</li>
          <li><strong>Sun in Cancer:</strong> The Sun in Cancer indicates a person who is emotional, sensitive, and nurturing. They are often family-oriented and enjoy spending time at home. They are also likely to be creative and have a strong imagination. However, they can also be moody, insecure, and clingy. They may also suffer from health problems related to the stomach or breasts.</li>
          <li><strong>Sun in Leo:</strong> The Sun is in its own sign in Leo, which makes it a very powerful placement. This gives the native a strong sense of self, confidence, and charisma. They are often natural leaders and enjoy being in the spotlight. They are also likely to be creative and have a strong sense of drama. However, they can also be egoistic, arrogant, and demanding. They may also suffer from health problems related to the heart or spine.</li>
          <li><strong>Sun in Virgo:</strong> The Sun in Virgo indicates a person who is practical, analytical, and detail-oriented. They are often perfectionists and have a strong work ethic. They are also likely to be health-conscious and have a love of learning. However, they can also be critical, judgmental, and overly concerned with details. They may also suffer from health problems related to the digestive system or intestines.</li>
          <li><strong>Sun in Libra:</strong> The Sun is debilitated in Libra, which means it is in its weakest position. This can give the native a lack of confidence and a tendency to be indecisive. They may also struggle with relationships and have difficulty asserting themselves. However, they can also be charming, diplomatic, and have a strong sense of justice. They may also suffer from health problems related to the kidneys or lower back.</li>
          <li><strong>Sun in Scorpio:</strong> The Sun in Scorpio indicates a person who is passionate, intense, and secretive. They are often very private people and can be difficult to get to know. They are also likely to be ambitious and have a strong will to succeed. However, they can also be jealous, possessive, and vengeful. They may also suffer from health problems related to the reproductive system or sexual organs.</li>
          <li><strong>Sun in Sagittarius:</strong> The Sun in Sagittarius indicates a person who is optimistic, adventurous, and philosophical. They are often free-spirited and enjoy exploring new ideas and places. They are also likely to be honest and straightforward. However, they can also be tactless, careless, and prone to exaggeration. They may also suffer from health problems related to the hips or thighs.</li>
          <li><strong>Sun in Capricorn:</strong> The Sun in Capricorn indicates a person who is ambitious, disciplined, and hardworking. They are often very practical and have a strong sense of responsibility. They are also likely to be successful in their careers. However, they can also be pessimistic, overly serious, and workaholics. They may also suffer from health problems related to the knees or bones.</li>
          <li><strong>Sun in Aquarius:</strong> The Sun in Aquarius indicates a person who is independent, unconventional, and humanitarian. They are often very creative and have a strong interest in social justice. They are also likely to be intelligent and have a unique perspective on the world. However, they can also be aloof, detached, and rebellious. They may also suffer from health problems related to the ankles or circulatory system.</li>
          <li><strong>Sun in Pisces:</strong> The Sun in Pisces indicates a person who is compassionate, imaginative, and spiritual. They are often very creative and have a strong connection to the arts. They are also likely to be intuitive and have a deep understanding of human nature. However, they can also be escapist, impractical, and prone to addiction. They may also suffer from health problems related to the feet or lymphatic system.</li>
        </ul>
      </section>
    </main>
  );
}

export default Sun;
