import React, { useEffect } from 'react';
import '../App.css';

function Relations() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Planetary Relations and Interactions</h1>

                <h2>Planetary Relationships</h2>

                <p>In Vedic Astrology, planets maintain complex relationships with each other that significantly influence their effects in a birth chart. These relationships are classified into two main categories: permanent relationships (which remain constant) and temporary relationships (which vary based on planetary positions in individual birth charts). Every planet has natural friends, neutrals, and enemies that remain consistent regardless of their positions in any birth chart. These permanent relationships reflect the inherent nature and compatibility between planetary energies. In order to keep things simple, we will not delve into discussions of temporary planetary relationships.</p>

                <h3>Basic Categories</h3>
                <ol className="enlarged-text">
                    <li>Friends</li>
                    <li>Neutral</li>
                    <li>Enemies</li>
                </ol>

                <img src="/planetary_relations_v2.png" alt="Planetary Relations Table" style={{ width: '95%', marginTop: '10px', marginBottom: '20px' }} />

                <h2>Parivartana (Mutual Exchange)</h2>
                <ul className="enlarged-text">
                    <li>Occurs when two planets occupy each other's signs</li>
                    <li>Considered stronger than conjunctions</li>
                    <li>Creates a lifelong effect</li>
                </ul>

                <h3>Characteristics</h3>
                <ul className="enlarged-text">
                    <li>Most beneficial between Kendra (angular) or Trine lords</li>
                    <li>Can be challenging when involving 6th, 8th, or 12th house lords (Dustana lords)</li>
                    <li>Effects manifest most strongly during Mahadasha periods</li>
                    <li>Can cause major life transformations</li>
                    <li>Generally helpful but can bring challenges depending on house lordships</li>
                </ul>

                <h3>Examples</h3>
                <ul className="enlarged-text">
                    <li>Sun in Mars' sign while Mars is in Sun's sign</li>
                    <li>Jupiter in Venus' sign while Venus is in Jupiter's sign</li>
                </ul>

                <h2>Combustion</h2>
                <p>Combustion (Asta) occurs when planets come within a certain radius of the Sun. This is a physical phenomenon where the Sun's fire element (tatva) purifies or burns up some of the material significations of the affected planet (graha). Combustion can occur across sign boundaries since it's based on degree measurements.</p>

                <h3>Key Points About Combustion</h3>
                <ul className="enlarged-text">
                    <li>When a planet is at a lower degree than the Sun and close to it, it becomes invisible from Earth, resulting in a particularly strong combustion</li>
                    <li>The Moon and Rahu/Ketu are not subject to combustion (Moon follows waxing/waning patterns instead)</li>
                </ul>

                <h3>Combustion Orbs for Different Planets</h3>
                <p>Different systems or astrologers may use slightly different orb sizes for determining combustion. Here's a table showing the more common approximate degrees within which planets can be combust:</p>

                <img src="/planet_combust.png" alt="Combustion Orbs Table" style={{ width: '65%', marginTop: '5px', marginBottom: '5px' }} />

                <h3>General Effects</h3>
                <ol className="enlarged-text">
                    <li>Planet's significations become "burnt up" or strained</li>
                    <li>Benefic planets (Venus, Jupiter) lose some beneficial qualities</li>
                    <li>Malefic planets (Mars, Saturn) can become more powerful in their malefic nature</li>
                    <li>Houses ruled by the combust planet also experience the combustion effect</li>
                </ol>

                <p>Note: Mercury and Venus come into combustion often and this has led to a number of different approaches from astrologers. Some astrologers minimize the impact of combustion on these planets or may even choose to ignore them outright. Some may use much smaller orb sizes to determine combustion for these planets. Hence, planets marked as being combust may not be treated as such. Other astrologers consider combustion of these planets to be very important and as something to be taken more seriously. If you have either Mercury or Venus combust in your chart, you will need to carefully determine how possible combustion effects have impacted your life. It is suggested that combustion be taken seriously and not be minimized.</p>

                <h3>Spiritual Significance</h3>
                <ul className="enlarged-text">
                    <li>The Sun represents the soul, so combustion can boost the spiritual significance of the affected planet</li>
                    <li>While materially challenging, combustion can be spiritually beneficial</li>
                </ul>

                <h3>Specific Planet Effects When Combust</h3>
                <ul className="enlarged-text">
                    <li>Mercury: May cause speech problems, communication issues, or learning difficulties, but can enhance intuitive intelligence</li>
                    <li>Venus: Can create relationship difficulties but deepens aesthetic appreciation</li>
                    <li>Mars: Can increase anger and antagonistic tendencies</li>
                    <li>Saturn: May cause loss or difficulties with father figures</li>
                    <li>Houses ruled by combust planets are becoming "burnt up". They have the effects of the combustion coming into that area.</li>
                </ul>

                <h2>Hemming of Planets</h2>
                <p>The hemming of planets is a principle in Vedic astrology that helps us understand how malefic planets can restrict or constrain the natural significations of other planets. In its most basic form, hemming occurs when a planet is surrounded by malefic planets on both sides. Think of it like a person being cornered between two hostile forces - their natural movement and expression becomes limited.</p>

                <p>When a planet experiences hemming, it finds itself caught between two natural malefics like Saturn, Mars, Rahu, or Ketu. This creates significant stress on the hemmed planet and can impair its ability to produce positive results during its dasha periods. The malefic planets essentially restrict the hemmed planet's significations.</p>

                <p>For example, suppose Venus is hemmed between two malefics. Since Venus represents marriage, relationships, comforts and luxuries in a birth chart, its hemming could manifest as difficulties or delays in marriage, challenges in relationships, or obstacles in experiencing life's pleasures during Venus dasha periods.</p>

                <p>The intensity of hemming depends on several factors. If the malefic planets are within close degrees to the hemmed planet, the effect becomes more pronounced. Similarly, if the malefic planets are strong in the chart (well-placed in signs and houses), their restrictive impact on the hemmed planet increases. The house placement of the hemming also matters - hemming in angular houses (1,4,7,10) or trine houses (1,5,9) can be particularly impactful since these are power positions in the chart.</p>

                <p>One of the most challenging scenarios is when the ascendant itself experiences hemming. Since the ascendant represents the native's overall life force, personality, and physical body, having it hemmed between malefics can create various obstacles and challenges in life. The native may face health issues, lack of confidence, or general difficulties in moving forward with their goals and aspirations.</p>

                <p>However, it's important to note that hemming doesn't always produce purely negative results. In some cases, the restrictive energy can actually help channel the planet's significations in a more focused way. For instance, a hemmed Mercury might lead someone to become more disciplined and structured in their communication and intellectual pursuits, rather than scattered and unfocused.</p>

                <p>The effects of hemming can be mitigated through various astrological remedies and by strengthening the hemmed planet through favorable transits and dashas. Additionally, if there are benefic aspects to the hemmed planet from Jupiter, Venus or a strong Moon, this can help reduce the restrictive impact of the hemming planets.</p>

                <h2>Planetary War (Graha Yuddha)</h2>

                <h3>Definition and Rules</h3>
                <ul className="enlarged-text">
                    <li>Occurs when planets are within 60 minutes (1 degree) of each other</li>
                    <li>Rare but significant phenomenon</li>
                    <li>Doesn't apply to nodes (Rahu/Ketu) or Sun</li>
                    <li>Higher degree planet wins the war, even by minutes</li>
                </ul>

                <h3>Effects</h3>
                <ul className="enlarged-text">
                    <li>Winning planet becomes stronger in the chart</li>
                    <li>Houses ruled by winning planet become stronger</li>
                    <li>Losing planet becomes weakened</li>
                    <li>Houses ruled by losing planet become weaker</li>
                </ul>
            </section>
        </main>
    );
}

export default Relations;
