import React, { useEffect } from 'react';
import '../App.css';

function Ascendants() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="ascendants">
        <h1>Introduction to Ascendants in Vedic Astrology</h1>
        
        <p>
          In Vedic astrology, the ascendant or "Lagna" is one of the most crucial aspects of a natal chart. It represents the zodiac sign that was rising on the eastern horizon at the exact time of a person's birth. This sign not only determines the first house in the chart but also sets the stage for the rest of the houses and their respective significations. The ascendant is considered to be the lens through which all other planetary influences are filtered, making it a cornerstone of Vedic astrological analysis.
        </p>
        <p>
          The ascendant influences a person’s physical appearance, general temperament, and the basic way they approach life. It marks the beginning of one's journey and can indicate the inherent strengths and weaknesses one might face. Each ascendant brings its own set of characteristics, impacting the individual's personality, relationships, career, and overall life path.
        </p>

        <h2>Detailed Analysis of Each Ascendant</h2>

        <h3>Aries Ascendant (Mesha Lagna)</h3>
        <p>
          Aries ascendants are known for their dynamic and energetic nature. Ruled by Mars, they are typically adventurous, confident, and assertive. They possess a pioneering spirit and are not afraid to take risks. However, they can sometimes be impulsive and quick-tempered. Their physical appearance often includes a strong athletic build and a prominent forehead.
        </p>

        <h3>Taurus Ascendant (Vrishabha Lagna)</h3>
        <p>
          Taurus ascendants are ruled by Venus, which endows them with a love for beauty, comfort, and stability. They are known for their practicality, patience, and determination. They often have a robust physique with a tendency towards a stocky build. They value material possessions and are typically very loyal in relationships.
        </p>

        <h3>Gemini Ascendant (Mithuna Lagna)</h3>
        <p>
          Gemini ascendants, ruled by Mercury, are characterized by their intellectual curiosity and adaptability. They are excellent communicators and thrive in social settings. Physically, they often have a slender frame and expressive features. They are versatile and enjoy exploring new ideas and experiences.
        </p>

        <h3>Cancer Ascendant (Karka Lagna)</h3>
        <p>
          Ruled by the Moon, Cancer ascendants are nurturing, intuitive, and emotionally sensitive. They have a strong connection to their family and home. They often have a rounded face and a somewhat softer, fluid physical appearance. They are empathetic and caring but can also be prone to moodiness.
        </p>

        <h3>Leo Ascendant (Simha Lagna)</h3>
        <p>
          Leo ascendants are ruled by the Sun, which gives them a regal and charismatic presence. They are natural leaders, confident, and creative. They often have a commanding presence with a robust, sometimes leonine physical appearance. They are proud and generous but can also be prone to arrogance.
        </p>

        <h3>Virgo Ascendant (Kanya Lagna)</h3>
        <p>
          Virgo ascendants, ruled by Mercury, are analytical, detail-oriented, and practical. They are known for their precision and love of order. Physically, they tend to have a clean-cut appearance with sharp features. They are health-conscious and often excel in fields that require meticulous work.
        </p>

        <h3>Libra Ascendant (Tula Lagna)</h3>
        <p>
          Ruled by Venus, Libra ascendants are charming, diplomatic, and sociable. They have a natural grace and often possess an attractive physical appearance. They seek harmony and balance in their lives and relationships. They can sometimes struggle with indecision due to their desire to please everyone.
        </p>

        <h3>Scorpio Ascendant (Vrischika Lagna)</h3>
        <p>
          Scorpio ascendants are ruled by Mars, giving them an intense and magnetic personality. They are deeply passionate, determined, and often very private. They typically have a piercing gaze and a strong muscular build. They are known for their resilience and ability to transform through challenges.
        </p>

        <h3>Sagittarius Ascendant (Dhanu Lagna)</h3>
        <p>
          Ruled by Jupiter, Sagittarius ascendants are optimistic, adventurous, and philosophical. They are often enthusiastic about travel and learning. Physically, they tend to be tall with a strong athletic build. They are known for their honesty and straightforwardness but can sometimes be overly blunt.
        </p>

        <h3>Capricorn Ascendant (Makara Lagna)</h3>
        <p>
          Capricorn ascendants, ruled by Saturn, are disciplined, responsible, and ambitious. They have a strong sense of duty and are often focused on long-term goals. They typically have a lean, angular physique. They are practical and grounded but can sometimes struggle with pessimism.
        </p>

        <h3>Aquarius Ascendant (Kumbha Lagna)</h3>
        <p>
          Ruled by Saturn, Aquarius ascendants are innovative, independent, and humanitarian. They often have a unique, sometimes unconventional appearance. They are intellectual and forward-thinking with a strong sense of social justice. They can sometimes be detached or aloof.
        </p>

        <h3>Pisces Ascendant (Meena Lagna)</h3>
        <p>
          Pisces ascendants are ruled by Jupiter, giving them a compassionate, dreamy, and intuitive nature. They are often artistic and spiritually inclined. Physically, they may have a soft, ethereal appearance. They are empathetic and gentle but can sometimes be escapist or overly idealistic.
        </p>
      </section>
    </main>
  );
}

export default Ascendants;
