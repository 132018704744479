import React, { useEffect } from 'react';
import '../App.css';

function Yogas2() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        loadAds();
    }, []);

    return (
        <main>
            <section>
                <h1>Key Solar and Lunar Yogas</h1>

                <h2>Solar Yogas</h2>
                <p>These yogas involve the Sun, which represents resources and vitality.</p>

                <h2>Ravi Yoga</h2>
                <p>Ravi Yoga is formed when two specific planetary positions occur simultaneously:</p>

                <ol className="enlarged-text">
                    <li>The Sun is positioned in the 10th house</li>
                    <li>The 10th lord occupies the 3rd house along with Saturn</li>
                </ol>

                <p>A person born under this yoga is characterized by:</p>
                <ul className="enlarged-text">
                    <li>Scholarly aptitude and learning</li>
                    <li>Expertise in scientific subjects</li>
                    <li>A passionate nature</li>
                    <li>High social standing, particularly earning respect from people in positions of authority</li>
                    <li>Achievement of significant reputation during their lifetime</li>
                </ul>

                <h2>Vesi Yoga</h2>
                <p>Vesi Yoga is formed when any planet except the Moon occupies the second house from the Sun in the birth chart. This yoga is generally considered beneficial, though its effects can vary depending on the nature of the planet involved.</p>

                <h3>General Effects</h3>
                <p>A person born with Vesi Yoga typically possesses:</p>
                <ul className="enlarged-text">
                    <li>A truthful nature</li>
                    <li>A tall stature</li>
                    <li>A balanced outlook on life</li>
                    <li>Good memory</li>
                    <li>Moderate wealth</li>
                    <li>Kind-heartedness</li>
                    <li>Virtuous disposition</li>
                </ul>

                <h3>Effects Based on Planet Type</h3>

                <h4>Benefic Planets</h4>
                <p>When benefic planets (Jupiter, Venus, Mercury) form this yoga, the native experiences enhanced positive effects:</p>
                <ul className="enlarged-text">
                    <li>Greater eloquence</li>
                    <li>Increased wealth</li>
                    <li>Ability to overcome enemies</li>
                    <li>More pronounced virtuous qualities</li>
                </ul>

                <h4>Malefic Planets</h4>
                <p>When malefic planets (Saturn, Mars) form this yoga, the native may experience some challenging effects:</p>
                <ul className="enlarged-text">
                    <li>Tendency toward laziness</li>
                    <li>Potential financial struggles</li>
                    <li>Risk of associating with negative influences</li>
                    <li>Possible visual impairments</li>
                </ul>

                <h3>Specific Planetary Effects</h3>
                <p>Different planets in Vesi Yoga position bring unique influences:</p>
                <ul className="enlarged-text">
                    <li>Mars: Brings valor in battle, renown, and expertise in fire-related matters</li>
                    <li>Mercury: Grants sweet speech, intelligence, attractive appearance, and strategic thinking</li>
                    <li>Jupiter: Bestows steady nature, truthfulness, wisdom, and courage in confrontations</li>
                    <li>Venus: Provides fame, fearlessness, respectability, and multiple virtues</li>
                    <li>Saturn: Creates business interest, but may lead to deceptive tendencies and conflicts with teachers</li>
                </ul>

                <h2>Voshi Yoga</h2>
                <p>Voshi Yoga occurs when any planet except the Moon occupies the twelfth house from the Sun in the birth chart. This yoga's effects are distinctly different based on whether benefic or malefic planets are involved.</p>

                <h3>General Effects</h3>
                <p>Common characteristics of Voshi Yoga include:</p>
                <ul className="enlarged-text">
                    <li>Good learning capacity</li>
                    <li>Eloquent speech</li>
                    <li>Charitable nature</li>
                    <li>Sharp memory</li>
                    <li>Generally virtuous disposition</li>
                </ul>

                <h3>Effects Based on Planet Type</h3>

                <h4>Benefic Planets (Subhavasi Yoga)</h4>
                <p>When formed by benefic planets, it creates Subhavasi Yoga, resulting in:</p>
                <ul className="enlarged-text">
                    <li>Increased wealth and fame</li>
                    <li>Enhanced learning abilities</li>
                    <li>Strong physical constitution</li>
                    <li>Government service opportunities</li>
                    <li>Patronage from authority figures</li>
                </ul>

                <h4>Malefic Planets (Papavasi Yoga)</h4>
                <p>When formed by malefic planets, it creates Papavasi Yoga, leading to:</p>
                <ul className="enlarged-text">
                    <li>Challenges in earning livelihood</li>
                    <li>Potential for untruthfulness</li>
                    <li>Difficulties with emotional control</li>
                    <li>Intellectual challenges</li>
                    <li>Less favorable physical appearance</li>
                </ul>

                <h3>Specific Planetary Effects</h3>
                <p>Each planet brings its unique influence when forming Voshi Yoga:</p>
                <ul className="enlarged-text">
                    <li>Mars: Inclines toward scientific education and altruism, but may create maternal conflicts</li>
                    <li>Mercury: Enhances learning and memory, but may affect health and wealth</li>
                    <li>Jupiter: Brings wisdom, wealth, and tendency to accumulate</li>
                    <li>Venus: Creates government service opportunities and royal favor, but may increase sensual desires</li>
                    <li>Saturn: Develops kindness and mature appearance, but may create relationship complications</li>
                </ul>

                <h2>Ubhayachari Yoga</h2>
                <p>Ubhayachari Yoga occurs when planets occupy both the 2nd and 12th houses from the Sun in a birth chart. This yoga specifically requires that planets other than the Moon (and Rahu/Ketu) be positioned on either side of the Sun, effectively hemming it in.</p>

                <h3>Effects of the Yoga</h3>
                <p>The effects of this yoga vary significantly depending on the nature of the planets involved:</p>

                <h4>When Caused by Benefic Planets</h4>
                <p>When benefic planets create this yoga, the native is blessed with:</p>
                <ul className="enlarged-text">
                    <li>Strong physical constitution</li>
                    <li>High status and ability to handle significant responsibilities</li>
                    <li>Wealth and material comforts</li>
                    <li>Physical attractiveness</li>
                    <li>Access to numerous luxuries and pleasures</li>
                    <li>Learning and wisdom</li>
                </ul>

                <h4>When Caused by Malefic Planets</h4>
                <p>If malefic planets form this yoga, the native may experience:</p>
                <ul className="enlarged-text">
                    <li>Poor health and frequent ailments</li>
                    <li>Servile or dependent nature</li>
                    <li>Financial difficulties or destitution</li>
                    <li>Negative personality traits</li>
                </ul>

                <p>Important note: This yoga does not form if the Sun stands alone without any planetary influence on either side. The specific effects can vary based on the particular combinations of planets involved in creating the yoga.</p>

                <h2>Lunar Yogas</h2>
                <p>These yogas involve the Moon, which represents the mind, emotions, and nurturing energy.</p>

                <h2>Adhi Yoga</h2>
                <p>Adhi Yoga is a powerful astrological combination (yoga) that occurs when natural benefic planets occupy specific houses in a birth chart. This yoga forms when the natural benefic planets (Mercury, Jupiter, and Venus) occupy the 6th, 7th, and 8th houses from the Moon. These benefics can be distributed across these houses in any combination. For instance, if the Moon is in Taurus, and you find Mercury and Jupiter in Virgo (6th house) with Venus in Leo (7th house), this would create an Adhi Yoga.</p>

                <p>Important note: The presence of malefic planets in these houses (6th, 7th, and 8th from Moon) can diminish or negate the effects of this yoga.</p>

                <h3>Effects of Adhi Yoga</h3>
                <p>When properly formed, Adhi Yoga bestows several beneficial results upon the native:</p>

                <h4>Career and Status:</h4>
                <ul className="enlarged-text">
                    <li>The native often attains high social status</li>
                    <li>May achieve leadership positions, particularly in government or military</li>
                    <li>Can rise to positions similar to ministers or high-ranking officials</li>
                    <li>The exact level of achievement depends on the strength of the planets involved</li>
                </ul>

                <h4>Personal Characteristics:</h4>
                <ul className="enlarged-text">
                    <li>Blessed with good health and longevity</li>
                    <li>Experiences prosperity and abundance in life</li>
                    <li>Develops a pleasing personality</li>
                    <li>Known for having a trustworthy and polite nature</li>
                    <li>Possesses good character</li>
                    <li>Experiences general happiness in life</li>
                </ul>

                <h2>Sunapha Yoga</h2>
                <p>Sunapha Yoga is an astrological combination that occurs when one or more planets (excluding the Sun) occupy the second house from the Moon in a birth chart. For example, if the Moon is placed in Gemini and Jupiter and Mercury are in Cancer, this yoga is formed. This yoga primarily influences wealth, status, and personal characteristics. The main effects of Sunapha Yoga include:</p>

                <ul className="enlarged-text">
                    <li>Enhanced capacity to generate self-earned wealth</li>
                    <li>Elevated social status and recognition</li>
                    <li>Increased intelligence and wisdom</li>
                    <li>Religious or spiritual inclination</li>
                    <li>Virtuous nature and quiet disposition</li>
                </ul>

                <p>The specific results of this yoga vary depending on which planet occupies the second house from the Moon:</p>
                <ul className="enlarged-text">
                    <li>Jupiter: Brings scholarly achievements, widespread recognition, substantial wealth, and success in religious or spiritual pursuits. The native often becomes a respected advisor or teacher.</li>
                    <li>Mercury: Grants proficiency in fine arts, music, and scriptures. The person becomes eloquent, intelligent, and financially successful.</li>
                    <li>Venus: Bestows efficiency, scholarship, and physical beauty. The native gains honor from authority figures and enjoys material comforts including property and vehicles.</li>
                    <li>Mars: Creates a valorous and wealthy personality, often leading to positions of command or leadership.</li>
                    <li>Saturn: Develops cleverness and skill, bringing wealth and respect from community, though it may not be favorable for the native's mother.</li>
                </ul>

                <h2>Anapha Yoga</h2>
                <p>Anapha Yoga forms when one or more planets (excluding the Sun) occupy the twelfth house from the Moon. For instance, if the Moon is in Aries and Jupiter and Venus are in Pisces, this yoga is present. While Sunapha Yoga emphasizes accumulation and possession, Anapha Yoga focuses more on spending and enjoyment. Its primary effects include:</p>

                <ul className="enlarged-text">
                    <li>Good health and physical appearance</li>
                    <li>Fame and recognition</li>
                    <li>Strong oratorical skills</li>
                    <li>Virtuous character</li>
                    <li>Material comforts and enjoyment</li>
                </ul>

                <p>The results vary based on the planet in the twelfth house:</p>
                <ul className="enlarged-text">
                    <li>Jupiter: Grants strength, virtue, and energy. The native becomes learned, wealthy, and receives recognition from authorities.</li>
                    <li>Mercury: Bestows excellence in performing arts (music, acting, dance) and poetry. The person becomes eloquent and gains honor.</li>
                    <li>Venus: Creates charm and attraction, brings material comforts and wealth, and gives a pleasant speaking manner.</li>
                    <li>Mars: Develops leadership qualities, courage, and boldness. The native becomes praiseworthy though potentially arrogant.</li>
                    <li>Saturn: Brings ownership of land and vehicles but may lead to dependency on others' wealth and unconventional pursuits.</li>
                </ul>

                <h2>Durudhara Yoga</h2>
                <p>Durudhara Yoga occurs when planets occupy both the 2nd and 12th houses from the Moon position. Importantly, the Sun is excluded from this consideration and does not contribute to forming this yoga.</p>

                <h3>Key Characteristics</h3>
                <p>A native born under this yoga typically gains:</p>
                <ul className="enlarged-text">
                    <li>Recognition through eloquent speech</li>
                    <li>Scholarly achievements</li>
                    <li>Virtuous nature and valor</li>
                    <li>Material wealth and physical comforts</li>
                    <li>Access to vehicles and servants</li>
                    <li>Freedom from enemies</li>
                </ul>

                <h3>Important Considerations</h3>

                <h4>Planetary Influence</h4>
                <p>The quality and strength of Durudhara Yoga significantly depends on the nature of the planets involved:</p>
                <ul className="enlarged-text">
                    <li>Benefic planets (like Jupiter, Venus, Mercury) create a superior and more auspicious yoga</li>
                    <li>Malefic planets (Mars, Saturn) can diminish the positive effects</li>
                    <li>The nodes (Rahu/Ketu) or Sun's close association can reduce the yoga's strength</li>
                </ul>

                <h4>Strength Factors</h4>
                <p>The yoga's effectiveness is influenced by:</p>
                <ul className="enlarged-text">
                    <li>The Moon's overall strength in the chart</li>
                    <li>The participating planets' dignities (whether they are in exaltation, own sign, friendly sign, or own Navamsha)</li>
                    <li>The specific combinations of planets involved (for example, Jupiter-Venus creates wisdom and prosperity, while Mars-Saturn might create challenging tendencies)</li>
                </ul>

                <h4>Planetary Combinations and Their Effects</h4>
                <ul className="enlarged-text">
                    <li>Mercury-Jupiter: Creates religious inclination, intelligence, wealth, and political success</li>
                    <li>Jupiter-Venus: Bestows wisdom, intelligence, fame, and ministerial positions</li>
                    <li>Venus-Mercury: Grants artistic abilities, heroic qualities, and social respect</li>
                    <li>Jupiter-Saturn: Creates wealth, learning, and a hardworking nature</li>
                    <li>Venus-Saturn: Brings cleverness, wealth, and family leadership</li>
                    <li>Mars-Saturn: Can create difficulties in relationships and temperament</li>
                    <li>Mars-Mercury: May create complexity in truthfulness and behavior</li>
                </ul>

                <h2>Kemadruma Yoga</h2>
                <p>Kemadruma Yoga occurs when there are no planets on either side of the Moon (in the 2nd and 12th houses from the Moon). It's important to note that the presence of the Sun in these houses does not prevent the formation of this yoga, as the Sun is not considered in this calculation.</p>

                <h3>Effects</h3>
                <p>This yoga is generally considered challenging for the native (person whose chart is being analyzed). Its effects may include:</p>
                <ul className="enlarged-text">
                    <li>Difficulties in financial matters</li>
                    <li>Challenges in maintaining relationships</li>
                    <li>Potential obstacles in education and learning</li>
                    <li>Issues with mental peace and general well-being</li>
                    <li>Reduction in the positive effects of other beneficial yogas in the chart</li>
                </ul>

                <h3>Cancellation of Kemadruma Yoga</h3>
                <p>The adverse effects of Kemadruma Yoga can be cancelled or significantly reduced under several circumstances:</p>

                <h4>1. Placement of Planets</h4>
                <ul className="enlarged-text">
                    <li>When planets occupy kendras (angular houses - 1st, 4th, 7th, or 10th) from the lagna (ascendant)</li>
                    <li>When planets occupy kendras from the Moon</li>
                    <li>When the Moon is aspected by all planets</li>
                </ul>

                <h4>2. Moon's Strength</h4>
                <p>When a strong Moon occupies a kendra and is either:</p>
                <ul className="enlarged-text">
                    <li>Associated with benefic planets (Mercury, Jupiter, or Venus)</li>
                    <li>Aspected by benefic planets</li>
                </ul>

                <h4>3. Special Conditions</h4>
                <ul className="enlarged-text">
                    <li>When the Moon is in its exaltation sign in the Navamsha and is aspected by Jupiter</li>
                    <li>When a full Moon occupies the lagna (1st house) in conjunction with a benefic planet</li>
                    <li>When the Moon is exalted in the 10th house and receives beneficial aspects</li>
                </ul>

                <h2>Gaja-Kesari Yoga</h2>
                <p>Gaja-Kesari Yoga forms when Jupiter occupies a kendra (angular house) from the Moon. This is a frequently occurring and generally beneficial yoga.</p>

                <h3>Key Requirements for Full Benefits</h3>
                <p>For the yoga to produce its full positive effects:</p>

                <h4>1. Jupiter's Condition</h4>
                <ul className="enlarged-text">
                    <li>Should not be combust (too close to the Sun)</li>
                    <li>Should not be debilitated</li>
                    <li>Ideally should be associated with or aspected by benefic planets</li>
                </ul>

                <h4>2. Moon's Condition</h4>
                <ul className="enlarged-text">
                    <li>Should not be debilitated</li>
                    <li>Should be under beneficial influences</li>
                </ul>

                <h3>Effects</h3>
                <p>When well-formed, Gaja-Kesari Yoga can bring:</p>
                <ul className="enlarged-text">
                    <li>Enhanced wealth and prosperity</li>
                    <li>Recognition and fame</li>
                    <li>Strong intellectual capabilities</li>
                    <li>Virtuous character</li>
                    <li>Respect from authority figures</li>
                    <li>Overall good fortune</li>
                </ul>

                <h3>Strength Assessment</h3>
                <p>The potency of Gaja-Kesari Yoga depends on several factors:</p>

                <h4>1. Planetary Strength</h4>
                <ul className="enlarged-text">
                    <li>The relative strength of both Jupiter and Moon</li>
                    <li>Their placement in specific houses</li>
                    <li>The signs they occupy</li>
                    <li>Aspects from other planets</li>
                </ul>

                <h4>2. House Positions</h4>
                <ul className="enlarged-text">
                    <li>The specific kendra position Jupiter occupies from the Moon</li>
                    <li>The houses both planets occupy when counted from the lagna</li>
                    <li>The overall chart context and other planetary influences</li>
                </ul>

                <p>Remember that while Gaja-Kesari is generally beneficial, its effects should be judged carefully considering all these factors, as its manifestation can vary significantly based on the overall horoscope.</p>
            </section>
        </main>
    );
}

export default Yogas2;
