import React, { useEffect } from 'react';
import '../App.css';

function Venus() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="venus">
        <h1>Venus in Vedic Astrology</h1>
        
        <p>
          Venus is the planet of love, beauty, and relationships. It is the most benefic planet in Vedic astrology, and its placement in a birth chart can have a significant impact on a person's life. Venus is associated with the following qualities:
        </p>
        <ul className="enlarged-text">
          <li>Love and relationships</li>
          <li>Beauty and attraction</li>
          <li>Creativity and the arts</li>
          <li>Pleasure and luxury</li>
          <li>Money and finances</li>
        </ul>
        <p>
          Venus is also the karaka (significator) of the 7th house of marriage. This means that Venus' placement in a birth chart can indicate the type of marriage a person will have as well as their relationships with their spouse and other partners.
        </p>
        <p>
          Venus is considered to be a benefic planet, but it can also give negative results if it is afflicted by malefic planets or placed in difficult houses. For example, a debilitated Venus (in Virgo) can indicate problems in relationships, while a combust Venus (too close to the Sun) can indicate a lack of passion or creativity.
        </p>

        <h2>Venus Through the Twelve Houses</h2>
        <ul className="enlarged-text">
          <li><strong>1st House:</strong> Venus in the 1st house gives the native a charming and attractive personality. They are often well-liked and popular. They may also be talented in the arts or have a strong interest in beauty and fashion.</li>
          <li><strong>2nd House:</strong> Venus in the 2nd house indicates wealth and prosperity. The native may have a talent for making money or be attracted to luxurious things. They may also have a beautiful voice or be skilled in the arts.</li>
          <li><strong>3rd House:</strong> Venus in the 3rd house indicates a love of communication and the arts. The native may be a writer, musician, or artist. They may also be good at networking and building relationships.</li>
          <li><strong>4th House:</strong> Venus in the 4th house indicates a happy and harmonious home life. The native may have a beautiful home and be surrounded by loved ones. They may also be interested in real estate or home decor.</li>
          <li><strong>5th House:</strong> Venus in the 5th house indicates creativity and romance. The native may be a talented artist, musician, or writer. They may also be passionate about love and relationships.</li>
          <li><strong>6th House:</strong> Venus in the 6th house can indicate health problems, but it can also indicate a talent for healing. The native may be a doctor, nurse, or other healthcare professional. They may also be interested in alternative medicine or natural healing.</li>
          <li><strong>7th House:</strong> Venus in the 7th house is a strong indicator of a happy and successful marriage. The native may be attracted to a partner who is beautiful, charming, and wealthy. They may also have a successful business partnership.</li>
          <li><strong>8th House:</strong> Venus in the 8th house can indicate a sudden loss of wealth or a difficult marriage. However, it can also indicate a talent for the occult or a deep understanding of human sexuality.</li>
          <li><strong>9th House:</strong> Venus in the 9th house indicates a love of travel and adventure. The native may be attracted to foreign cultures and philosophies. They may also be interested in religion or spirituality.</li>
          <li><strong>10th House:</strong> Venus in the 10th house indicates a successful career in the arts, entertainment, or fashion. The native may be a famous actor, musician, or designer. They may also be a successful businesswoman or entrepreneur.</li>
          <li><strong>11th House:</strong> Venus in the 11th house indicates financial gain and wish fulfillment. The native may have a lot of friends and be popular in social circles. They may also be lucky in love and relationships.</li>
          <li><strong>12th House:</strong> Venus in the 12th house can indicate a loss of wealth or a difficult marriage. However, it can also indicate a deep spiritual connection or a talent for meditation and prayer.</li>
        </ul>

        <h2>Venus Through the Zodiac Signs</h2>
        <ul className="enlarged-text">
          <li><strong>Aries:</strong> Venus in Aries indicates a passionate and impulsive lover. They are attracted to excitement and adventure in relationships.</li>
          <li><strong>Taurus:</strong> Venus in Taurus indicates a sensual and down-to-earth lover. They are attracted to stability and security in relationships.</li>
          <li><strong>Gemini:</strong> Venus in Gemini indicates a witty and charming lover. They are attracted to intelligence and communication in relationships.</li>
          <li><strong>Cancer:</strong> Venus in Cancer indicates a nurturing and emotional lover. They are attracted to home and family in relationships.</li>
          <li><strong>Leo:</strong> Venus in Leo indicates a proud and confident lover. They are attracted to attention and admiration in relationships.</li>
          <li><strong>Virgo:</strong> Venus in Virgo indicates a practical and helpful lover. They are attracted to intelligence and efficiency in relationships.</li>
          <li><strong>Libra:</strong> Venus in Libra indicates a romantic and idealistic lover. They are attracted to beauty and harmony in relationships.</li>
          <li><strong>Scorpio:</strong> Venus in Scorpio indicates a passionate and intense lover. They are attracted to depth and mystery in relationships.</li>
          <li><strong>Sagittarius:</strong> Venus in Sagittarius indicates a free-spirited and adventurous lover. They are attracted to independence and exploration in relationships.</li>
          <li><strong>Capricorn:</strong> Venus in Capricorn indicates an ambitious and status-conscious lover. They are attracted to success and achievement in relationships.</li>
          <li><strong>Aquarius:</strong> Venus in Aquarius indicates an unconventional and independent lover. They are attracted to intelligence and originality in relationships.</li>
          <li><strong>Pisces:</strong> Venus in Pisces indicates a compassionate and romantic lover. They are attracted to spirituality and creativity in relationships.</li>
        </ul>
      </section>
    </main>
  );
}

export default Venus;
